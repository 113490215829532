import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './QuestionSubmit.module.css';
import Button from '../../ui/buttons/Button';
import {
  UpdateCustomQuestion,
  UpdateChangesToApprove,
} from '../../../store/actions';

// imports for syncfusion rich text editor
import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
import {
  Image,
  HtmlEditor,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import { updateObject, updateCustomQuestion } from '../../../utility/utility';

const QuestionSubmit = ({ customQuestionId }) => {
  const { isLoading, customQuestions, error } = useSelector(
    (store) => store.customQuestions
  );
  const dispatch = useDispatch();

  const [currentText, setCurrentText] = useState('');
  const [currentAnswer, setCurrentAnswer] = useState('');
  const [customQuestion, setCustomQuestion] = useState({});
  const [readWrite, setReadWrite] = useState('write');

  useEffect(() => {
    if (
      Object.keys(customQuestions).length > 0 &&
      Object.keys(customQuestions).includes(customQuestionId.toString())
    ) {
      const myCustomQuestion = customQuestions[customQuestionId];
      setCustomQuestion(myCustomQuestion);
      const questionFields =
        myCustomQuestion.question.questionTextFields.filter(
          (lang) => lang.languageId === 9
        );
      if (questionFields?.length) {
        setCurrentText(questionFields[0].questionText);
        setCurrentAnswer(questionFields[0].answerText);
      }
      setReadWrite(
        myCustomQuestion.customQuestionStatus.status === 'New'
          ? 'write'
          : 'read'
      );
    }
  }, [customQuestions, customQuestionId]);

  const updateDataHandler = () => {
    const newObj = updateCustomQuestion(customQuestion, {
      langSpec: {
        languageId: 9,
        questionText: currentText,
        answerText: currentAnswer,
      },
      status: 'Requested',
    });
    dispatch(UpdateCustomQuestion(newObj));
    dispatch(UpdateChangesToApprove(true));
    setReadWrite('read');
  };

  const handleChange = (e, id) => {
    const requestValue = e.value;
    if (id === 'text') {
      setCurrentText(requestValue);
    } else if (id === 'answer') {
      setCurrentAnswer(requestValue);
    }
  };

  const handleEdit = () => {
    setReadWrite('write');
  };
  // To re-add the option to add images:
  // 1. Add 'Image' in the array below in the desired position.
  // 2. Replace both injects with the one commented below:
  // <Inject services={[Image, Toolbar, Link, HtmlEditor, QuickToolbar]} />
  const toolbarSettings = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
      '|',
      'CreateLink',
      '|',
      'SourceCode',
      'Undo',
      'Redo',
    ],
  };

  return (
    <div className={styles.wrapper}>
      <div data-testid={'id-questionSubmit'} key={customQuestionId}>
        <h1>{customQuestion?.question?.name || ''}</h1>
        {readWrite === 'read' ? (
          <div className={styles.description}>
            <p className={styles.bold}>Question text</p>
            <p dangerouslySetInnerHTML={{ __html: currentText }}></p>
            <p className={styles.bold}>Question answers</p>
            <p dangerouslySetInnerHTML={{ __html: currentAnswer }}></p>
          </div>
        ) : (
          <div className={styles.description}>
            <p className={styles.bold}>Please add question text</p>
            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              value={currentText}
              change={(e) => handleChange(e, 'text')}
            >
              <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>
            <p className={styles.bold}>Please add question answers</p>
            <RichTextEditorComponent
              toolbarSettings={toolbarSettings}
              value={currentAnswer}
              change={(e) => handleChange(e, 'answer')}
            >
              <Inject services={[Toolbar, Link, HtmlEditor, QuickToolbar]} />
            </RichTextEditorComponent>
          </div>
        )}
      </div>
      <div className={styles.btnContainer}>
        {readWrite === 'read' ? (
          <Button
            type="primary"
            className={styles.btnPosRight}
            handleOnClick={() => handleEdit()}
          >
            Edit
          </Button>
        ) : (
          <Button
            type="primary"
            className={styles.btnPosRight}
            handleOnClick={() => updateDataHandler()}
          >
            Save
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionSubmit;
