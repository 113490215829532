import React from 'react';
import Button from '../../ui/buttons/Button';
import styles from './ConfirmNoCustomQ.module.css';

const ConfirmNoCustomQ = ({
  id,
  handleApprove,
  handleClose,
  noLocalQ,
  changesToSave,
}) => {
  console.log(changesToSave);
  return (
    <div>
      <div className={styles.Close} onClick={() => handleClose()}>
        X
      </div>
      <div className={styles.Title}>
        <h4>Final selection warning</h4>
      </div>
      <div className={styles.Message}>
        <div
          dangerouslySetInnerHTML={{
            __html: noLocalQ
              ? 'You are about to submit your <b>final</b> module selection <b>without adding any local questions</b>. Are you sure you want to continue?'
              : changesToSave
              ? `You are about to submit your <b>final</b> module selection with <b style={{color="#de350b"}}>unsaved changes</b> in module selection. These changes <b>will not be saved!<b> Are you sure you want to continue?`
              : 'You are about to submit your <b>final</b> module selection. Are you sure you want to continue?',
          }}
        ></div>
      </div>
      <div className={styles.btnContainer}>
        <Button handleOnClick={() => handleClose()} type="secondary">
          Cancel
        </Button>
        <Button
          handleOnClick={() => {
            handleApprove();
            handleClose();
          }}
          type="primary"
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ConfirmNoCustomQ;
