import { Paragraph, HeadingLevel, TextRun } from 'docx';
import { Document, Packer } from 'docx';
import { saveAs } from 'file-saver';
import { GetFormattedLocalQuestionName, getStatus } from '../../../utility/utility';
import { cloneDeep as _cloneDeep } from 'lodash';

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Array} customQuestions Array containing all local questions for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @param {Number} modulesTitleCtr 0; required for function to work as intended.
 * @param {Number} localQuestionsTitleCtr 0; required for function to work as intended.
 * @param {Array} modulesArr Array of two empty items. The first one will contain the modules specific to this wave, and the second one will containg the local questions after the function is executed. The modules and local questions will have the format required by the 'docx' module to be able to generate the Document object and save the file.
 *
 * @description The function accepts all data required to generate the preview and populates the first child of the 'modulesArr' argument with the modified data for modules in the format accepted by the 'docx' document. The second child of 'modulesArr' array is populated by the data for local questions. Once the function finished its execution 'modulesArr' will contain all the data for the wave's module selection ( including if it has country specific data, the modules and their data, as well as the local questions).
 */
const buildDocxPageContents = (
  selectedModules,
  customQuestions,
  isCountrySpecificQuestionsDetected,
  modulesTitleCtr,
  localQuestionsTitleCtr,
  modulesArr,
  isDownloadAll
) => {
  console.log(
    'is it country specific:',
    isCountrySpecificQuestionsDetected,
    'true/false:',
    !isCountrySpecificQuestionsDetected,
    !isDownloadAll
  );
  const emptyLine = new Paragraph({
    text: ' ',
  });
  const qTextPara = new Paragraph({
    children: [
      new TextRun({
        text: 'Question text:',
        bold: true,
      }),
    ],
  });

  let qnQuestions = [];
  let qnAnswerRows = [];
  let qnAnswerCols = [];
  let qnAnswerText = [];

  const lastKeyInd =
    Object.keys(selectedModules)[Object.keys(selectedModules).length - 1];
  // For loop required to turn the contents of each module question into docx elements
  for (let i = 0; i <= lastKeyInd; i++) {
    qnQuestions[i] = selectedModules[i].questions.map((question) =>
      _cloneDeep(question.questionTextFields)
    );
    qnAnswerRows[i] = selectedModules[i].questions.map((question) =>
      _cloneDeep(question.questionTextFields[0].answerRowList)
    );
    qnAnswerCols[i] = selectedModules[i].questions.map((question) =>
      _cloneDeep(question.questionTextFields[0].answerColList)
    );
    qnAnswerText[i] = selectedModules[i].questions.map((question) =>
      _cloneDeep(question.questionTextFields[0].answerText)
    );

    // primes answerRows[i], answerRols[i] and answerText[i] to then be turned into paragraphs
    let currentQnAnswerRows = qnAnswerRows[i];
    let currentQnAnswerCols = qnAnswerCols[i];
    let currentQnAnswerText = qnAnswerText[i];

    qnQuestions = qnQuestions[i].map(
      (moduleQuestion) =>
        new Paragraph({
          text: moduleQuestion[0].questionText,
        })
    );

    const qnName = selectedModules[i].questions.map(
      (question) =>
        new Paragraph({
          children: [
            new TextRun({
              text: question.name,
              size: 28,
            }),
          ],
          heading: HeadingLevel.HEADING_4,
        })
    );

    const qnBase = selectedModules[i].questions.map(
      (question) =>
        new Paragraph({
          text: `Base: ${question.base ? question.base : 'All respondents'}`,
        })
    );

    const qnScripting = selectedModules[i].questions.map((question) => {
      if (!question.scriptingInstructions) return; // without this the page breaks or it creates an empty
      // row after between qnBase and qnAddRules, where qnScripting would be
      return new Paragraph({
        text: `Scripting instructions: ${question.scriptingInstructions}`,
      });
    });

    const qnAddRules = selectedModules[i].questions.map(
      (question) =>
        new Paragraph({
          text: `Additional rules: ${
            question.additionalRules
              ? question.additionalRules
              : 'No additional rules'
          }`,
        })
    );
    const qnVariableCodes = selectedModules[i].questions.map((question) => {
      return new Paragraph({
        children: [
          new TextRun({ text: "Question name: ", bold: true }),
          new TextRun({
            text: question.variableCode,
            bold: true,
          }),
        ],
      });
    });
    let moduleQnsDocxContent = [];
    // Stores the docx content for each module's questions

    for (let x = 0; x < qnName.length; x++) {
      // Looping through the questions for each module.
      moduleQnsDocxContent.push(
        emptyLine,
        qnName[x],
        qnBase[x],
        qnScripting[x],
        qnAddRules[x],
        emptyLine,
        qnVariableCodes[x],
        emptyLine,
        qTextPara,
        qnQuestions[x]
      );

      if (currentQnAnswerText[x]) {
        moduleQnsDocxContent.push(
          new Paragraph({
            children: [new TextRun({ text: 'Answers: ', bold: true })],
          })
        );
        moduleQnsDocxContent.push(
          new Paragraph({ text: currentQnAnswerText[x] })
        );
      }

      if (currentQnAnswerRows[x].length > 0) {
        if (currentQnAnswerCols[x].length > 0 && !currentQnAnswerText[x]) {
          // if the answers are structured in rows and columns adds the text "Answer Rows:" before them
          moduleQnsDocxContent.push(
            new Paragraph({
              children: [new TextRun({ text: 'Answer Rows:', bold: true })],
            })
          );
        } else {
          // if the answers are structured only in rows adds the text "Answers:" before them
          moduleQnsDocxContent.push(
            new Paragraph({
              children: [new TextRun({ text: 'Answers:', bold: true })],
            })
          );
        }
        currentQnAnswerRows[x]
          .sort((a, b) => a.sequence - b.sequence)
          .map((answerRow) => {
            // adds answerRows content for each question
            moduleQnsDocxContent.push(
              new Paragraph({ text: `${answerRow.code}. ${answerRow.text}` })
            );
          });
      }
      if (
        currentQnAnswerCols[x].length > 0 &&
        currentQnAnswerRows[x].length < 1
      ) {
        // if the answers are structured only in columns adds the text "Answers:" before them
        moduleQnsDocxContent.push(
          new Paragraph({
            children: [new TextRun({ text: 'Answers:', bold: true })],
          })
        );
      } else if (
        currentQnAnswerCols[x].length > 0 &&
        currentQnAnswerRows[x].length > 0
      ) {
        // if the answers are structured in rows and columns adds the text "Answer Columns:" before them

        moduleQnsDocxContent.push(
          new Paragraph({
            children: [new TextRun({ text: 'Answer Columns:', bold: true })],
          })
        );
      }
      currentQnAnswerCols[x]
        .sort((a, b) => a.sequence - b.sequence)
        .map((answerCol) => {
          // adds answerRows content for each question
          moduleQnsDocxContent.push(
            new Paragraph({ text: `${answerCol.code}.  ${answerCol.text}` })
          );
        });
    }
    // Adds Module Questions into the variable that will be used to render the document

    modulesArr[0].push({
      children: [
        modulesTitleCtr === 0 && // required for adding the h1 title Modules
          // adding the title at the top of everything causes a page break after
          // it, so this workaround is used
          new Paragraph({
            children: [
              new TextRun({
                text: 'Modules',
                size: 64,
              }),
            ],
            heading: HeadingLevel.HEADING_1,
          }),
        emptyLine,
        modulesTitleCtr === 0 &&
          !isCountrySpecificQuestionsDetected &&
          new Paragraph({
            children: [
              new TextRun({
                text: 'No country specific data detected for this country',
                color: 'ff0000',
              }),
            ],
          }),
        emptyLine,
        new Paragraph({
          children: [
            new TextRun({
              text: selectedModules[i].name,
              size: 44,
            }),
          ],
          heading: HeadingLevel.HEADING_2,
        }),
        new Paragraph({
          children: [
            new TextRun({
              text: selectedModules[i].description,
              bold: true,
            }),
          ],
        }),
        emptyLine,
        ...moduleQnsDocxContent,
      ],
    });
    modulesTitleCtr++;
  }
  
  const customQns = customQuestions.map((customQuestion) => {    
      return {
        customNamePrefix: customQuestion.customNamePrefix??'',
        customQuestionName: customQuestion.question?.name,
        customQuestionStatus: customQuestion.customQuestionStatus.status,
        isLegalApproved: customQuestion.isLegalApproved
          ? 'Question and translations have been legally approved'
          : 'Question and translations have not been legally approved',
        variableCode: customQuestion.question.variableCode,
        questionType: customQuestion.question.questionType?.name,
        base: customQuestion.question.base,
        scriptingInstruction: customQuestion.scriptingInstruction,
        dpInstructions : customQuestion.dpInstructions,
        questionTextFields: customQuestion.question?.questionTextFields?.map((qtf) => {
          return {
            questionText: qtf.questionText,
            questionAnswer: qtf.answerText,
            language: qtf.language.name,
            isTranslationApproved: qtf.isTranslationApproved
              ? 'Translation has been approved.'
              : 'Translation has not been approved.',
          };
        })
      };
  });
  const customQnDocxContent = [];
  for (let i = 0; i < customQuestions.length; i++) {

    const customQnName = new Paragraph({
      children: [
        new TextRun({
            text: `${i + 1}. ${GetFormattedLocalQuestionName(customQns[i]?.customNamePrefix, customQns[i].customQuestionName)}`,
            size: 28,
        }),
      ],
      heading: HeadingLevel.HEADING_4,
    });

    const status = getStatus(customQns[i].customQuestionStatus);
    const customQnStatus =  new Paragraph({
        children: [
          new TextRun({
            text: `Question status: ${status}`,
            bold: true,
          }),
        ],
      });
    const customQnsTextFields = customQns[i].questionTextFields.map((questionTextField) => {
      return [
        new Paragraph({
          children: [
            new TextRun({ text: 'Question text', bold: true }),
            questionTextField.language
              ? new TextRun({
                  text: ` - ${questionTextField.language}:`,
                  bold: true,
                })
              : new TextRun({
                  text: ':',
                  bold: true,
                }),
            new TextRun({
              text: ` ${questionTextField.questionText.replace(
                /<[^>]*>/g,
                ''
              )}`,
            }),
          ],
        }),
        new Paragraph({
          children: [
            new TextRun({ text: 'Answer: ', bold: true }),
            new TextRun({
              text: `${
                questionTextField?.questionAnswer?.replace(/<[^>]*>/g, '')
              }`,
            }),
          ],
        }),
        new Paragraph({
          text: questionTextField.isTranslationApproved,
        })
      ];
    });
    const customQnsVarCode = new Paragraph({
          children: [
            new TextRun({ text: 'Variable code: ', bold: true }),
            new TextRun({
              text:
              customQns[i].variableCode &&
                `${customQns[i].variableCode?.replace(/<[^>]*>/g, '')}`,
            }),
          ],
        });
    const customQnsType = new Paragraph({
          children: [
            new TextRun({ text: 'Question type: ', bold: true }),
            new TextRun({
              text:
              customQns[i].questionType &&
                `${customQns[i].questionType?.replace(/<[^>]*>/g, '')}`,
            }),
          ],
        });

    const customQnsBase = new Paragraph({
          children: [
            new TextRun({ text: 'Base: ', bold: true }),
            new TextRun({
              text: customQns[i].base && `${customQns[i].base?.replace(/<[^>]*>/g, '')}`,
            }),
          ],
        });

    const customQnsScriptingInst = new Paragraph({
          children: [
            new TextRun({ text: 'Scripting instructions: ', bold: true }),
            new TextRun({
              text:
              customQns[i].scriptingInstruction &&
                `${customQns[i].scriptingInstruction?.replace(/<[^>]*>/g, '')}`,
            }),
          ],
        });
    const customDPTables = customQns[i].dpInstructions?.map((dpInstruction, index) => {
        return [
          emptyLine, 
          new Paragraph({
          children: [
            new TextRun({
              text: `${'Instructions Table ' + (index+1) +':'}`
            }),
          ],
        }),  
          new Paragraph({
          children: [
              new TextRun({ text: 'Question Name: ', bold: true }),
              new TextRun({
                  text: dpInstruction?.tableName && `${dpInstruction?.tableName.replace(/<[^>]*>/g, '')}`,
              })
            ]
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Short Title: ', bold: true }),
              new TextRun({
                  text: dpInstruction?.tableTitle && `${dpInstruction?.tableTitle.replace(/<[^>]*>/g, '')}`,
              })
            ]
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Base / Filter: ', bold: true }),
              new TextRun({
                  text: dpInstruction?.tableBase && `${dpInstruction?.tableBase.replace(/<[^>]*>/g, '')}`,
              })
            ]
          }),
          new Paragraph({
            children: [
              new TextRun({ text: 'Instructions: ', bold: true }),
              new TextRun({
                  text: dpInstruction?.dpInstruction && `${dpInstruction?.dpInstruction.replace(/<[^>]*>/g, '')}`,
              })
            ]
          }),
          new Paragraph({
            children: [      
              new TextRun({ text: 'Additional Information: ', bold: true }),
              new TextRun({
                  text: dpInstruction?.dpAdditionalInformation && `${dpInstruction?.dpAdditionalInformation.replace(/<[^>]*>/g, '')}`,
              })
            ]
          })
        ]
      });
    let customQnsLegalApproved = new Paragraph({
          text: customQns[i].isLegalApproved,
        });

    customQnDocxContent.push(
      emptyLine,
      customQnName,
      customQnStatus,
      emptyLine,
      customQnsVarCode,
      customQnsType,
      customQnsBase
    );
    customQnsTextFields.forEach((questionTextFieldQuesAns) => {
      customQnDocxContent.push(
        emptyLine,
        ...questionTextFieldQuesAns
      );
    });
    customQnDocxContent.push(
      emptyLine,
      customQnsScriptingInst,
      emptyLine,
      new Paragraph({
        children: [
          new TextRun({
            text: 'DP Table Instructions',
            bold: true,
          }),
        ],
      }),  
    );
    customDPTables.forEach((dpInstructions) => {
      customQnDocxContent.push(
      ...dpInstructions
     );
    });
    customQnDocxContent.push(
      emptyLine,
      customQnsLegalApproved
    );
  }
  // Adds Local Questions into the variable that will be used to render the document
  modulesArr[1].push({
    children: [
      localQuestionsTitleCtr === 0 &&
        customQuestions.length > 0 &&
        new Paragraph({
          children: [
            new TextRun({
              text: 'Local Questions',
              size: 64,
            }),
          ],
          heading: HeadingLevel.HEADING_1,
        }),
      ...customQnDocxContent,
    ],
  });
  localQuestionsTitleCtr++;
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Object} doc Document object containing the contents of the file that will be saved.
 * @param {String} fileName Name of the file that will be saved.
 *
 * @description This function accepts a Document object and a file name as arguments, and based on them it creates and downloads a .docx file.
 */
const saveDocumentToFile = (doc, fileName) => {
  // Create a mime type that will associate the new file with Microsoft Word
  const mimeType =
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  // Create a Blob containing the Document instance and the mimeType
  Packer.toBlob(doc).then((blob) => {
    const docblob = blob.slice(0, blob.size, mimeType);
    // Save the file using saveAs from the file-saver package
    saveAs(docblob, fileName);
  });
};

/**
 * @function
 * @memberof QuestionnairePreview
 * @param {Array} selectedModules Array containing all modules that have been selected for this wave.
 * @param {Array} customQuestions Array containing all local questions for this wave.
 * @param {Boolean} isCountrySpecificQuestionsDetected Boolean for if a wave contains country specific questions.
 * @param {Object} wave Object containing the current wave in the store.
 *
 * @description This function builds the page contents of the .docx file that will be generated ( using the function 'buildDocxPageContents' ), creates a new 'Document' and assigns the created page contents to it. After building the content and assigning it to the document it saves the file as a .docx with the name being the name of the wave using the function 'saveDocumentToFile'.
 */
export const generateWordDocument = (
  selectedModules,
  waveName,
  incomingCustomQuestions,
  incomingIsCountrySpecificQuestionsDetected,
  isDownloadAll
) => {
  let modulesArr = [[], []];
  let modulesTitleCtr = 0;
  let localQuestionsTitleCtr = 0;
  let customQuestions = incomingCustomQuestions || [];
  let isCountrySpecificQuestionsDetected =
    incomingIsCountrySpecificQuestionsDetected || false;

  buildDocxPageContents(
    selectedModules,
    customQuestions,
    isCountrySpecificQuestionsDetected,
    modulesTitleCtr,
    localQuestionsTitleCtr,
    modulesArr,
    isDownloadAll
  );

  let doc = new Document({
    sections: [...modulesArr[0], ...modulesArr[1]],
  });
  saveDocumentToFile(doc, `${waveName}.docx`);
};
