import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ModuleContent.module.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DisplayAnswers from '../../ui/text/DisplayAnswers';
// NOSONAR
import { dummyQuestions } from './dummyData';

/**
 * @class ModuleContent
 * @param {Number} moduleId Id of module being rendered.
 * @param {Boolean} isPreview This will be 'true' if the component is used in the 'QuestionnairePreview' component, to preview a module that is part of the wave's module selection. It will be 'false' when rendering a module that was clicked on the 'ModuleSelection' page.
 *
 * @description A component that renders all questions of a wave, a message stating that there is no country specific data ( if this is the case, or no message at all, if there is country specific data for this wave ). When viewed in the context of the 'ModuleSelection' component it allows the user to click on 'Show/Hide details...' for each question to view additional question details ( if they are available ). When used in the context of 'QuestionnairePreview', this option is not available, as all question details are shown by default.
 *
 * The **ModuleContent** component is used in the **QuestionnairePreview** component.
 *
 * @returns {JSX} Component containing all questions for this wave. This component is used to preview the module content when used in 'QuestionnairePreview', and to view the contents of the module questions when used in the 'ModuleSelection' component.
 */
const ModuleContent = ({ moduleId, isPreview, downloadAll }) => {
  const modules = useSelector((state) => {
    if (downloadAll) {
      return state.modules.projectModules;
    } else {
      return state.modules.modules;
    }
  });
  // console.log(moduleId, modules, 'MODULES FIX THE FORMAT OF projectModules in the state!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
  const moduleName = modules[moduleId].name;
  const moduleDescription = modules[moduleId].description;

  //real data
  const questions = modules[moduleId].questions;
  // Worth noting that using dummy data is indended only in the context of ModuleSelection.utility. Using dummy data in the QuestionnairePreview component breaks it.
  //dummy data
  // const questions = dummyQuestions

  const [showQnDetails, setShowQnDetails] = useState({});
  const showDetailsGlobal = useSelector(
    (state) => state.modules.showQuestionDetails
  );
  const { countries } = useSelector((state) => state.wave);
  const { wave } = useSelector((state) => state.wave);
  let { country } = useParams();
  const [
    isCountrySpecificQuestionsDetected,
    setCountrySpecificQuestionsDetected,
  ] = useState(false);

  /**
   * @function
   * @memberof ModuleContent
   * @param {Number} id 'questionId' of the question that is part of the module that's currently being viewed.
   *
   * @description Hides / shows question details for a specific question when the 'Hide/show details...' text is clicked.
   *
   * This function only works when viewing a wave's the module selection, not the preview of it. This is becasue for the preview all question details are shown.
   */
  const questionDetailsToggle = (id) => {
    let oldVal = showQnDetails[id];
    let updateObj = { ...showQnDetails };
    updateObj[id] = !oldVal;
    setShowQnDetails({ ...updateObj });
  };

  /**
   * @method useEffect
   * @memberof CountryMenu
   * @param {Array} questions
   * @param {Boolean} showDetailsGlobal
   *
   * @description This effect maps over each item in the 'questions' array , and for each of them creates a new object in 'myObj', with the key set to the question's 'questionId' value. The value of this key is the value of 'showDetailsGlobal', if one is available, or 'false' if not. This means that when rendered all questions withh either show their additional details, or not show them, depending on the value of 'showDetailsGlobal'.
   * This effect has ['questions', and 'showDetailsGlobal'] as dependencies, but as they do not change, the effect is triggerred once, on the initial component load, once the values of the two dependencies are available.
   */
  useEffect(() => {
    let newObj = {};
    questions.map((question) => {
      newObj[question.questionId] = showDetailsGlobal || false;
    });
    setShowQnDetails(newObj);
  }, [questions, showDetailsGlobal]);

  /**
   * @method useEffect
   * @memberof ModuleContent
   * @param {Array} countries
   * @param {Object} wave All data pertaining to the current wave.
   *
   * @description Checks the current country, and if it has country specific data, and depending on if it does or not, it sets the value'countrySpecificQuestionsDetected' of to the boolean. If there is no country specific data for this country, a red message will be rendered on the screen ( based on the value of 'countrySpecificQuestionsDetected' ), stating that this is the case.
   * This effect has ['countries', and 'wave'] as dependencies, but as they do not change, the effect is triggerred once, on the initial component load, once the values of the two dependencies are available.
   */
  useEffect(() => {
    if (country !== undefined && countries && countries.length > 0) {
      const selectedCountryDetails = countries.find((x) => x.name === country);
      selectedCountryDetails &&
      selectedCountryDetails.projectStatusForCountrySpecificData?.find(
        (el) => el.projectId === wave.projectId
      )?.hasCountrySpecificQuestions
        ? setCountrySpecificQuestionsDetected(true)
        : setCountrySpecificQuestionsDetected(false);
    }
  }, [countries, wave]);

  return (
    <div
      data-testid="id-ModuleContent"
      className={`${styles.moduleContainer} moduleContent-print`}
    >
      {!isCountrySpecificQuestionsDetected && !isPreview && (
        <p
          className={`${styles.headers} ${styles.contentBold} ${styles.notificationMsg}`}
        >
          No country specific data detected for this country
        </p>
      )}
      <h1 className={styles.headers}>{moduleName}</h1>
      <p className={`${styles.headers} ${styles.contentBold}`}>
        {moduleDescription}
      </p>
      {questions.map((question) => {
        return (
          <div
            className={`${styles.content} moduleContent-print`}
            data-testid={question.questionId}
            key={question.questionId}
          >
            <h4 className={`${styles.contentBold}`}>{question.name}</h4>
            {(showQnDetails[question.questionId] || isPreview) && (
              <div>
              <div className={`${styles.QnDetails} ${styles.contentItalic}`}>
                <div>
                  Base: {question.base ? question.base : 'All respondents'}
                </div>
                {question.scriptingInstructions && (
                  <div>
                    Scripting instructions: {question.scriptingInstructions}
                  </div>
                )}
                <div>
                  Additional rules:{' '}
                  {question.additionalRules
                    ? question.additionalRules
                    : 'No additional rules'}
                </div>
                
              </div>
              <div>
              <b>Question name: {question.variableCode}</b>
              </div>
              </div>
            )}
            {!isPreview && (
              <div
                className={`${styles.QnDetailsToggle} printHidden`}
                onClick={(id) => questionDetailsToggle(question.questionId)}
              >
                {showQnDetails[question.questionId]
                  ? 'Hide details...'
                  : 'Show details...'}
              </div>
            )}
            <div>
              {question.questionTextFields.map((qnField) => {
                return (
                  <div
                    key={qnField.questionTextFieldId}
                    className={styles.QuestionWrapper}
                  >
                    <div>
                      <div className={styles.contentBold}>Question text:</div>
                      <div>{qnField.questionText}</div>
                    </div>
                    {qnField.answerText && (
                      <div>
                        <div className={styles.contentBold}>Answers: </div>
                        <div>{qnField.answerText}</div>
                      </div>
                    )}
                    {qnField?.answerRowList.length > 0 && (
                      <DisplayAnswers
                        answers={qnField.answerRowList}
                        id={`${qnField.questionTextFieldId}-rows`}
                        subtitle={
                          qnField?.answerColList?.length > 0 ||
                          qnField?.answerText?.length > 0
                            ? 'Answer rows:'
                            : 'Answers:'
                        }
                        isPreview={isPreview}
                      />
                    )}
                    {qnField?.answerColList.length > 0 && (
                      <DisplayAnswers
                        answers={qnField.answerColList}
                        id={`${qnField.questionTextFieldId}-cols`}
                        subtitle="Answer columns:"
                        isPreview={isPreview}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ModuleContent.propTypes = {
  moduleId: PropTypes.any.isRequired,
};

ModuleContent.defaultProps = {
  moduleId: '3',
};

export default ModuleContent;
