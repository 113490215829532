import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep as _cloneDeep } from 'lodash';

import styles from './DisplayAnswers.module.css';

const DisplayAnswers = ({ id, answers, subtitle, isPreview }) => {
  const [expandList, setExpandList] = useState(false);
  const [orderedAnswers, setOrderedAnswers] = useState([...answers])  

  useEffect(() => {
    let sortAnswerList = _cloneDeep(answers)
    sortAnswerList.sort((a,b) => {
      return a.sequence - b.sequence
    })

    setOrderedAnswers(sortAnswerList)
  }, [answers])

  return(
    <>
        {
          <div>
            {<div className={styles.contentBold}>{subtitle}</div>}
            <ul key={id} className={styles.AnswerList}>
              {orderedAnswers.map((ans, ind) => {
                return(
                  <li key={ans.sequence} 
                  className={
                    `${!isPreview && (!expandList && ind > 9 && orderedAnswers.length > 15)?styles.HideListItem:styles.ShowListItem}`
                    }><div className={styles.AnswerCode}>{ans.code}.</div><div className={styles.AnswerText}>{ans.text}</div></li>
                  )
              })}
              {!isPreview && !expandList && orderedAnswers.length > 15 && (<><li>...</li><li onClick={()=>setExpandList(true)} className={styles.ExpandToggle}>Show more...</li></>)}
              {!isPreview && expandList && orderedAnswers.length > 15 && <li onClick={()=>setExpandList(false)} className={styles.ExpandToggle}>Show less...</li>}
            </ul>
          </div>
        }
      
    </>
  )
};

DisplayAnswers.propTypes = {
  answers: PropTypes.any,
  id: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
};

DisplayAnswers.defaultProps = {
  id: -1,
  subtitle: 'default',
};

export default DisplayAnswers;
