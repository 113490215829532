import React, { useState } from "react";
import Button from "../buttons/Button";
import styles from './WaveDelete.module.css'

function WaveDeletePopup ({waveName, handleClose}) {
  const [input, setInput] = useState('')
  const [showError, setShowError] = useState(false)

  const handleClick = () => {
    console.log(input, 'input name')
    if(waveName === input){
      handleClose('delete')
    }
    else {
      setShowError(true)
    }    
  }

  const handleInputUpdate = (e) => {
    if(showError && (e.target.value === waveName)){
      setShowError(false)
    }
    setInput(e.target.value)
  }
 return(
    <>
      <div className={styles.Close} onClick={()=>handleClose('cancel')}>X</div>
      <div className={styles.Title}>
        <h4>Delete wave</h4>
      </div>
      <div className={styles.Message}>
        <div>
          You are about to delete wave {waveName}.{<br/>}Please confirm by typing the wave name in the input box below.
        </div>        
        <div className={styles.Input}><input value={input} onInput={e => handleInputUpdate(e)}/></div>
      </div>
      {showError && <div className={styles.DeleteError}>The provided name does not match the wave you are trying to delete.</div>}
      <div className={styles.Btn}>
        <Button handleOnClick={handleClick} type='primary' >DELETE</Button>
      </div>
      
    </>
 )
}

export default WaveDeletePopup