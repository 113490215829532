import 'bootstrap/dist/css/bootstrap.css';
import './global_styles/global.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import store from './store/store';
import { Provider } from 'react-redux';
import { registerLicense } from '@syncfusion/ej2-base';
import AuthProvider from './provider/AuthProvider';
import { injectStoreInJs } from './utility/injectStore'
// Registering Syncfusion license key
registerLicense(
  'Mgo+DSMBaFt6QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRbQlhiTH5Uc0xgXXlfcnM=;ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0BjXnpXc3BTRGhf;Mgo+DSMBMAY9C3t2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Wd0BjXnpXc3BTQmdU'
);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
const msalInstance = new PublicClientApplication(msalConfig);
injectStoreInJs(store);
root.render(
    <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <AuthProvider>
                <App />
          </AuthProvider>
        </MsalProvider>
    </Provider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
