import { updateObject, sortCountriesOrder } from '../../utility/utility';
import * as actionTypes from '../actions/actionTypes';
import { updateWaveProcess } from '../../utility/utility';

const initialState = {
  wave: {},
  countries: {},
  activeWave: -1,
  isLoading: false,
  error: null,
};

const clearWave = (state) => {
  return {
    ...state,
    wave: {},
  };
};

const wavesStart = (state) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const wavesFail = (state, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

const getWaveSuccess = (state, action) => {
  // console.log('waveSuccess', action);
  return {
    ...state,
    wave: action.wave,
    isLoading: false,
    error: null,
  };
};

const updateWaveCurrentStatus = (state, action) => {
  const updatedWaveProcesses = updateWaveProcess(
    state.wave.waveProcesses,
    action.name,
    {
      status: action.status,
      processId: action.id,
    }
  );

  return updateObject(state, {
    wave: {
      ...state.wave,
      waveStatusId: action.id,
      waveStatus: {
        waveStatusId: action.id,
        status: action.name,
      },
      waveProcesses: updatedWaveProcesses,
    },
  });
};

const getCountriesWavesSuccess = (state, action) => {
  // console.log('Countries/Waves Success', action, state);
  return {
    ...state,
    countries: action.countries,
    isLoading: false,
    error: null,
  };
};

const createWaveStart = (state) => {
  return {
    ...state,
    isLoading: false,
    error: null,
  };
};

const createWaveSuccess = (state, action) => {
  const updatedWaves = updateObject(state.wave[action.id], action.wave);
  // console.log(updatedWaves, 'createWaveSuccess has fired ------------------');
  return updateObject(state, {
    wave: { ...state.wave, [action.id]: updatedWaves },
  });
};

const createWaveFail = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const updateWaveSuccess = (state, action) => {
  // console.log('updateWaveSuccessState', state.wave);
  const updatedWave = updateObject(state.wave, action.wave);
  return {
    ...state,
    wave: updatedWave,
    isLoading: false,
    error: null,
  };
};

const updateWaveFail = (state, action) => {
  return {
    isLoading: false,
    error: action.error,
  };
};

const deleteWaveSuccess = (state, action) => {
  if (action?.payload?.countryId && action?.payload?.waveId) {
    const waveId = parseInt(action?.payload?.waveId);
    const countryId = parseInt(action?.payload?.countryId);
    const updatedCountries = state.countries.map((country) => {
      if (country.countryId === countryId) {
        return {
          ...country,
          waves: country.waves.filter((wave) => wave.waveId !== waveId),
        };
      }
      return country;
    });

    return {
      ...updateObject(state, {
        countries: sortCountriesOrder(updatedCountries),
      }),
      isLoading: false,
      error: null,
    };
  }
  return {
    ...state,
    isLoading: false,
    error: null,
  };
};

const GetProcessesStart = (state) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const getProcessesSuccess = (state, action) => {
  return {
    ...state,
    processes: action.processes,
    isLoading: false,
    error: null,
  };
};

const copyWaveStart = (state) => {
  return {
    ...state,
    isLoading: false,
    error: null,
  };
};

const GetWaveStatusesStart = (state) => {
  return {
    ...state,
    isLoading: true,
    error: null,
  };
};

const getWaveStatusesSuccess = (state, action) => {
  return {
    ...state,
    waveStatuses: action.waveStatuses,
    isLoading: false,
    error: null,
  };
};

const copyWaveSuccess = (state, action) => {
  const updatedWave = updateObject(state.wave, action.wave);
  return {
    ...state,
    wave: updatedWave,
    isLoading: false,
    error: null,
  };
};

const waveReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WAVE_START:
      return wavesStart(state);
    case actionTypes.GET_WAVE_SUCCESS:
      return getWaveSuccess(state, action);
    case actionTypes.GET_WAVE_FAIL:
      return wavesFail(state, action);
    case actionTypes.GET_COUNTRIES_WAVES_START:
      return wavesStart(state);
    case actionTypes.GET_COUNTRIES_WAVES_SUCCESS:
      return getCountriesWavesSuccess(state, action);
    case actionTypes.GET_COUNTRIES_WAVES_FAIL:
      return wavesFail(state, action);
    case actionTypes.CREATE_WAVE_SUCCESS:
      return createWaveSuccess(state, action);
    case actionTypes.CREATE_WAVE_FAIL:
      return createWaveFail(state, action);
    case actionTypes.CREATE_WAVE_START:
      return createWaveStart(state);
    case actionTypes.UPDATE_WAVE_START:
      return wavesStart(state);
    case actionTypes.UPDATE_WAVE_SUCCESS:
      return updateWaveSuccess(state, action);
    case actionTypes.UPDATE_WAVE_FAIL:
      return updateWaveFail(state, action);
    case actionTypes.DELETE_WAVE_START:
      return wavesStart(state);
    case actionTypes.DELETE_WAVE_SUCCESS:
      return deleteWaveSuccess(state, action);
    case actionTypes.DELETE_WAVE_FAIL:
      return wavesFail(state, action);
    case actionTypes.DELETE_WAVE_PROCESS:
      return wavesStart(state);
    case actionTypes.GET_PROCESSES_START:
      return GetProcessesStart(state);
    case actionTypes.GET_PROCESSES_FAIL:
      return wavesFail(state, action);
    case actionTypes.GET_PROCESSES_SUCCESS:
      return getProcessesSuccess(state, action);
    case actionTypes.COPY_WAVE_START:
      return copyWaveStart(state);
    case actionTypes.COPY_WAVE_SUCCESS:
      return copyWaveSuccess(state, action);
    case actionTypes.GET_WAVESTATUSES_START:
      return GetWaveStatusesStart(state);
    case actionTypes.GET_WAVESTATUSES_FAIL:
      return wavesFail(state, action);
    case actionTypes.GET_WAVESTATUSES_SUCCESS:
      return getWaveStatusesSuccess(state, action);
    case actionTypes.CLEAR_WAVE_DATA:
      return clearWave(state);
    case actionTypes.UPDATE_WAVE_STATUS:
      return updateWaveCurrentStatus(state, action);
    default:
      return state;
  }
};

export default waveReducer;
