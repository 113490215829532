import React from 'react';
import styles from './Modal.module.css';


function ModalDialog({show, content}) {
  if(!show){
    return null
  }

  return (
      <div className={styles.Modal}>
        <div className={styles.ModalContent}>
          {content}
        </div>
      </div>
  );
}

export default ModalDialog;