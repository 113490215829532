import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccordionMenuItem from './AccordionMenuItem';

const AccordionMenu = ({
  items,
  onClickSubitem,
  onClickItem,
  idCurrentSubitem,
  currentCountryId,
  scrollTo,
}) => {
  const [current, setCurrent] = useState(idCurrentSubitem);

  const ref = useRef({});

  useLayoutEffect(() => {
    // running on timeout if they navigate directly to the link. First time doenst work with regular useEffect.
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      const timer = setTimeout(() => {
        ref.current[scrollTo]?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }, 10);
    }
  }, []);

  useEffect(() => {
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      const timer = setTimeout(() => {
        // timeout required for the mappping over and rendering of elements to start
        ref.current[scrollTo]?.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      }, 10);
    }
  }, [ref.current, scrollTo]);

  return (
    <div>
      {items.map((item) => {
        return (
          <div
            key={item.id}
            id={`menu-country-${item.id}`}
            ref={(el) => (ref.current[item.id] = el)}
          >{
            <AccordionMenuItem
              onClickSubitem={onClickSubitem}
              onClickItem={onClickItem}
              idCurrentSubitem={current}
              setCurrent={setCurrent}
              currentCountryId={currentCountryId}
              {...item}
            />}
          </div>
        );
      })}
    </div>
  );
};

AccordionMenu.propTypes = {
  onClickSubitem: PropTypes.func.isRequired,
  idCurrentSubitem: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired,
};

AccordionMenu.defaultProps = {
  onClickSubitem: (id) => {
    return id;
  },
  idCurrentSubitem: -1,
  items: [
    {
      id: -1,
      name: 'default',
      expanded: false,
      subitems: [
        {
          id: -1,
          name: 'default',
        },
      ],
    },
  ],
};

export default AccordionMenu;
