import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

const Button = ({ id, handleOnClick, type, disabled, tabIndex, children }) => {
  return (
    <button
      className={`${styles.btn} ${styles[type]}`}
      disabled={disabled}
      onClick={() => handleOnClick(id)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          handleOnClick(id);
        }
      }}
      tabIndex={tabIndex}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'selection',
    'bold',
    'close',
    'selectionMini',
    'listItem',
  ]),
  disabled: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func,
  tabIndex: PropTypes.number.isRequired,
};

Button.defaultProps = {
  id: -1,
  label: 'default',
  type: 'default',
  disabled: false,
  handleOnClick: (id) => {
    return id;
  },
  tabIndex: 0,
};

export default Button;
