import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatusGrid.module.css';
import * as moment from 'moment';

const StatusGridItem = ({
  process,
  status,
  statusType,
  startDate,
  endDate,
  projectType,
}) => {
  const formattedStartDate = moment(startDate).utc().format('DD/MM/YYYY');
  const formattedEndDate = moment(endDate).utc().format('DD/MM/YYYY');

  if (
    projectType === 'Incidence tracker' &&
    process.name === 'Module selection'
  )
    return;
  return (
    <>
      <div className={`${styles.cell} ${styles.content}`}>{process.name}</div>
      <div className={`${styles.cell} ${styles.content} ${styles[statusType]}`}>
        {status}
      </div>
      <div className={`${styles.cell} ${styles.content}`}>
        {formattedStartDate}
      </div>
      <div className={`${styles.cell} ${styles.content}`}>
        {formattedEndDate}
      </div>
    </>
  );
};

StatusGridItem.propTypes = {
  process: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  statusType: PropTypes.oneOf(['default', 'success', 'progress', 'error']),
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

StatusGridItem.defaultProps = {
  process: { name: 'default' },
  status: 'default',
  statusType: 'default',
  startDate: 'default',
  endDate: 'default',
};

export default StatusGridItem;
