import { React, useEffect, useState } from 'react';
import styles from './CountryMenu.module.css';
import AccordionMenu from '../../ui/nav/AccordionMenu';
import { countBy } from 'lodash';

/**
 * @class CountryMenu
 * @param {Array} countries Array of all countries a user has access to depending on their ux privileges.
 * @param {Function} onClickWave Sets the 'currentWaveId' state variable to the wave id of the wave that was clicked.
 * @param {Function} onClickCountry Sets the 'currentCountryId' state variable to the country id of the country that was clicked.
 * @param {String} scrollTo The 'countryId' of the country that will be scrolled into view on page load if a country is included in the URL params.
 * @param {Number} currentCountryId If there is a country in the URL params 'currentCountryId' will equal the 'countryId' of that country. If a country is clicked in the 'CountryMenu' component the country will be scrolled into view, and the value of 'currentCountryId' will be set to the 'countryId' value of the country that was clicked.
 *
 *
 * @description This component works as a container for the 'AccordionMenu' component. It sets up the appropriate props required for the child component to be able to render a list of countries that are clickable, and scroll into view when clicked.
 *
 * The **CountryMenu** component is used in the **WaveSelection** component.
 *
 * @returns {JSX} The component'AccordionMenu', which will be used for rendering all countries.
 */

const CountryMenu = ({
  countries,
  onClickWave,
  onClickCountry,
  scrollTo,
  currentCountryId,
}) => {
  const [countryWavesList, setCountryWavesList] = useState([]);

  /**
   * @function
   * @memberof CountryMenu
   * @param {Array} myCountries Array of objects corresponding to each country that the user has access to.
   *
   * @description Creates a new array based on the 'myCountries' argument. For each country the properties that are not needed are removed, and a new 'subitems' array is created, only containing the necessary key-value pairs.
   *
   * @returns {Array} Array of objects, with each object representing a country, and containing the following properties:
   *
   * * **id** - Number - Id of country item in the countryWavesList array.
   * * **name** - String - name country item in the countryWavesList array.
   * * **expanded** - Boolean - true for all countries.
   * * **subitems** - Array - all waves corresponding to this country. Each child contains the wave 'id' and 'name'.
   */
  const GetCountryWavesList = (myCountries) => {
    // create a list of countries
    const myItems = myCountries.map((country) => {
      // create a list of waves per country
      const mySubItems = country.waves.map((wave) => {
        return { id: wave.waveId, name: wave.name };
      });

      // return the object for the country
      return {
        id: country.countryId,
        name: country.name,
        expanded: true,
        subitems: mySubItems,
      };
    });

    return myItems;
  };

  /**
   * @method useEffect
   * @memberof CountryMenu
   * @param {Array} countries
   *
   * @description If the user has access to one or more countries it sets the value of 'countryWavesList', to the return value of 'GetCountryWavesList', which is a simplified version of the 'countries' array, where only the necessary key-value pairs are kept. This is required for the 'AccordionMenu' component to be able to render the countries the user has access to.
   *
   * The effect is executed every time the 'countries' prop updates.
   */
  useEffect(() => {
    if (countries && countries[0]) {
      setCountryWavesList(GetCountryWavesList(countries));
    }
  }, [countries]);

  return (
    <div className={styles.PanelContainer}>
      <div className={styles.MenuPos}>
        <AccordionMenu
          onClickSubitem={onClickWave}
          onClickItem={onClickCountry}
          items={countryWavesList}
          scrollTo={scrollTo}
          currentCountryId={currentCountryId}
        />
      </div>
      {/* hide for now
      <div className={styles.AdHocBtnContainer}>
        <Button>Ad-hoc surveys</Button>
      </div> */}
    </div>
  );
};

export default CountryMenu;
