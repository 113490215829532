import React from 'react';
import PropTypes from 'prop-types';
import styles from './ComplexButton.module.css';
import deleteIcon from '../../../assets/svg/delete.svg';

const ComplexButton = ({
  id,
  handleOnClick,
  handleOnClickIcon,
  iconType,
  tabIndex,
  children,
  disableSecondaryBtn,
  focusedQ,
}) => {
  let iconSrc = '';
  let iconAlt = '';
  if (iconType === 'delete') {
    iconSrc = deleteIcon;
    iconAlt = 'delete';
  }

  return (
    <div
      className={`${styles.btn_container} ${
        id === focusedQ ? styles.btn_container_clicked : ''
      }`}
    >
      <button
        aria-label="primarybutton"
        tabIndex={tabIndex}
        onClick={() => {
          handleOnClick(id);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            handleOnClick(id);
          }
        }}
      >
        {children}
      </button>
      {disableSecondaryBtn ? null : (
        <button
          aria-label="iconbutton"
          className={styles.icon_btn}
          tabIndex={tabIndex}
          onClick={() => {
            handleOnClickIcon(id);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              handleOnClickIcon(id);
            }
          }}
        >
          <img src={iconSrc} alt={iconAlt} />
        </button>
      )}
    </div>
  );
};

ComplexButton.propTypes = {
  id: PropTypes.node.isRequired,
  handleOnClick: PropTypes.func,
  handleOnClickIcon: PropTypes.func,
  iconType: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  children: PropTypes.node.isRequired,
  disableSecondaryBtn: PropTypes.bool,
};

ComplexButton.defaultProps = {
  id: -1,
  handleOnClick: (id) => {
    return id;
  },
  handleOnClickIcon: (id) => {
    return id;
  },
  iconType: 'delete',
  tabIndex: 0,
  children: '',
  disableSecondaryBtn: false,
};

export default ComplexButton;
