import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Tabs.module.css';

const Tabs = ({ labelsTabs, idCurrent, handleOnClick }) => {
  const [current, setCurrent] = useState(idCurrent);

  useEffect(() => {
    if (current !== idCurrent) {
      setCurrent(idCurrent);
    }
  }, [idCurrent]);

  const clickHandler = (id) => {
    setCurrent(id);
    handleOnClick(id);
    return id;
  };

  return (
    <div>
      {labelsTabs.map((label, index) => {
        return (
          <button
            aria-selected={label.id === current}
            key={index}
            className={`${styles.btn} 
            ${label.id === current && styles.activeBtn} 
            ${index === 0 && styles.btnFirst} 
            ${index === labelsTabs.length - 1 && styles.btnLast}`}
            onClick={(e) => {
              e.preventDefault();
              clickHandler(label.id);
            }}
          >
            {label.name}
          </button>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  labelsTabs: PropTypes.array.isRequired,
  idCurrent: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  labelsTabs: [{ id: 0, name: 'default' }],
  idCurrent: 0,
  handleOnClick: (id) => {
    return id;
  },
};

export default Tabs;
