import React from 'react';
import styles from './Logo.module.css';
import { Link } from 'react-router-dom';

const Logo = (props) => {
  return (
    <Link to="/" className={styles.Link}>
      <div className={`${styles.Logo} ${props.customStyle}`}>
        <span className={styles.Orange}>GfK</span>Author
      </div>
    </Link>
  );
};

export default Logo;
