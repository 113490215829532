import React from 'react';
import styles from './Dropdown.module.css';

const Dropdown = ({
  id,
  label,
  value = '',
  options,
  onChange,
  customStyle,
  placeholder,
  name,
}) => {
  const shouldHidePlaceholder = value !== '';
  return (
    <div className={`${customStyle} ${styles.Dropdown}`} key={id}>
      <label>{label}</label>
      <select value={value} onChange={onChange} name={name}>
        <option
          value={undefined}
          disabled={shouldHidePlaceholder}
          hidden={shouldHidePlaceholder}
        >
          {placeholder || 'Select an option'}
        </option>
        {options?.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
