import React from 'react';
import PropTypes from 'prop-types';
import classes from './QuestionTypeSelection.module.css';
import { useState } from 'react';

const QuestionTypeSelection = ({
  getClicked,
  tabIndex,
  questionTypes,
  activeBtnId,
}) => {
  const [activeBtn, setActiveBtn] = useState(activeBtnId ? activeBtnId : '1');

  const clickHandler = (id) => {
    setActiveBtn(id);
    getClicked(id);
    return id;
  };

  return (
    <div data-testid="id-questionTypeSelection">
      {questionTypes.map((obj, index) => (
        <button
          className={`${classes.button} ${classes.borderMiddle} ${
            index === 0 && classes.borderLeft
          } ${index === questionTypes.length - 1 && classes.borderRight} ${
            activeBtn === obj.id && classes.activeBtn
          }`}
          key={obj.id}
          data-testid={obj.id}
          onClick={() => clickHandler(obj.id)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              getClicked(obj.id);
            }
          }}
          tabIndex={tabIndex}
        >
          {obj.name}
        </button>
      ))}
    </div>
  );
};

QuestionTypeSelection.propTypes = {
  getClicked: PropTypes.func,
  tabIndex: PropTypes.number,
  questionTypes: PropTypes.array.isRequired,
};

QuestionTypeSelection.defaultProps = {
  questionTypes: [{ id: 0, name: 'default' }],
  activeBtnId: '1',
  getClicked: (id) => {
    return id;
  },
};

export default QuestionTypeSelection;
