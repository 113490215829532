import modulesReducer from './modulesReducer';
import demoReducer from './demoReducer';
import customQuestionsReducer from './customQuestionsReducer';
import waveReducer from './waveReducer';
import appReducer from './appReducer';
import notificationReducer from './notificationReducer';
import messageReducer from './messageReducer'

export default {
  modules: modulesReducer,
  customQuestions: customQuestionsReducer,
  demo: demoReducer,
  wave: waveReducer,
  app: appReducer,
  notifications: notificationReducer,
  message: messageReducer
};
