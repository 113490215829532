import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ToggleComponent.module.css';
import { UpdatePageToggle } from '../../../store/actions';

const ToggleComponent = () => {
  const showQuestionDetails = useSelector(
    (state) => state.modules?.showQuestionDetails || false
  );

  const dispatch = useDispatch();

  const handlePageToggle = () => {
    dispatch(UpdatePageToggle(!showQuestionDetails));
  };
  return (
    <div
      className={`${styles.PageToggleWrapper} toggled${showQuestionDetails} printHidden`} // toggled${showQuestionDetails} and printHidden are required for preview print
      onClick={() => handlePageToggle()}
    >
      <div className={styles.PageToggleText}>Show question details</div>
      <div
        className={`${styles.PageToggle} ${
          showQuestionDetails ? styles.On : styles.Off
        }`}
      >
        <div className={styles.CircleBtn}></div>
      </div>
    </div>
  );
};

export default ToggleComponent;
