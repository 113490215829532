import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionDisplay.module.css';

import iconUp from '../../../assets/svg/accordion_up.svg';
import iconDown from '../../../assets/svg/accordion_down.svg';
import Button from '../buttons/Button';
import StatusGrid from '../grid/StatusGrid';
import { getFormattedDate } from '../../../utility/utility';
const AccordionDisplayItem = ({
  waveId,
  name,
  project,
  waveStatus,
  waveProcesses,
  testLink,
  reportingLink,
  expanded,
  currentWaveId,
  onClickModuleSelection,
  onClickEditWave,
  statusToStatusType,
  editWavesPrivilege,
  onClickModulePreview,
}) => {
  const [showDetail, setShowDetail] = useState(expanded);
  const [ref, setRef] = useState(null);
  const refCurrentWave = useRef(null);    

  useEffect(() => {
    // if the waveId equals the currentWaveId expand the details and set ref to the ref of the currentWave
    if (waveId === currentWaveId) {
      setShowDetail(true);
      setRef(refCurrentWave);
    } else {
      setRef(null);
    }
  }, [currentWaveId]);

  useEffect(() => {
    refCurrentWave.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [ref]);

  const waveFieldworkObj = useRef(
    waveProcesses.find(
      (waveProcess) => waveProcess.process?.name === 'Fieldwork'
    )
  );

  return (
    <div
      data-testid={waveId}
      aria-label={name}
      aria-expanded={showDetail}
      ref={ref}
    >
      {/* collapsed */}
      {!showDetail && (
        <div className={styles.item}>
          <header>
            {/* the toggle button */}
            <div className={styles.displayLeft}>
              <button
                className={styles.itemBtn}
                aria-label={name}
                onClick={() => {
                  setShowDetail(!showDetail);
                }}
              >
                {showDetail ? (
                  <img src={iconUp} alt="-" />
                ) : (
                  <img src={iconDown} alt="+" />
                )}
                {name}
                {waveFieldworkObj.current && waveFieldworkObj.current.startDate
                  ? ' | ' + getFormattedDate(waveFieldworkObj.current.startDate)
                  : null}
              </button>
              {/* the module selection button */}
              {waveProcesses.find((waveProcess) => waveProcess.process.name === 'Module selection') !== undefined ? (
                <Button
                  type="selection"
                  id={waveId}
                  handleOnClick={onClickModuleSelection}
                >
                  Module selection
                </Button>
              ) : null}
            </div>
            {}
            <div className={styles.displayRight}>
              Status: {waveStatus.status}
            </div>
          </header>
        </div>
      )}
      {/* expanded */}
      {showDetail && (
        <div className={`${styles.detail} ${styles.detailGrow}`}>
          <header>
            {/* the toggle button */}
            <button
              className={styles.detailBtn}
              aria-label={name}
              onClick={() => {
                setShowDetail(!showDetail);
              }}
            >
              {showDetail ? (
                <img src={iconUp} alt="-" />
              ) : (
                <img src={iconDown} alt="+" />
              )}
              {name}
              {waveFieldworkObj.current?.startDate
                ? ' | ' + getFormattedDate(waveFieldworkObj.current.startDate)
                : null}
            </button>
            <div className={styles.displayRight}>
              Status: {waveStatus.status}
            </div>
          </header>
          {/* the details */}
          <div className={styles.detailContent}>
            <StatusGrid
              statusItems={waveProcesses}
              testlink={testLink}
              reportinglink={reportingLink}
              statusToStatusType={statusToStatusType}
              projectType={project.name}
            />
          </div>
          <footer>
            <div className={styles.displayRight}>
              {/* the module selection button */}
              {waveProcesses.find((waveProcess) => waveProcess.process.name === 'Module selection') !== undefined ? (
                <div className={styles.btnContainer}>
                  <Button
                    type="primary"
                    id={waveId}
                    handleOnClick={onClickModuleSelection}
                  >
                    Module selection
                  </Button>
                  <Button
                    type="primary"
                    id={waveId}
                    handleOnClick={onClickModulePreview}
                  >
                    Preview
                  </Button>
                </div>
              ) : null}
              {editWavesPrivilege ? (
                <Button
                  type="secondary"
                  id={waveId}
                  handleOnClick={onClickEditWave}
                >
                  Edit wave details
                </Button>
              ) : null}
            </div>
          </footer>
        </div>
      )}
    </div>
  );
};

AccordionDisplayItem.propTypes = {
  waveId: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  project: PropTypes.object.isRequired,
  waveStatus: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClickModuleSelection: PropTypes.func.isRequired,
  onClickModulePreview: PropTypes.func.isRequired,
};

AccordionDisplayItem.defaultProps = {
  waveId: -1,
  name: 'default',
  project: { name: 'default' },
  waveStatus: { status: 'default' },
  expanded: false,
  onClickModuleSelection: (waveId) => {
    return waveId;
  },
  onClickModulePreview: (waveId) => {
    return waveId;
  },
};

export default AccordionDisplayItem;
