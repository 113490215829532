import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { UpdateCustomQuestion } from '../../../store/actions';

import styles from './ApproveCustomQuestion.module.css';
import { updateCustomQuestion, GetFormattedLocalQuestionName } from '../../../utility/utility';
import Tabs from '../../ui/nav/Tabs';
import Button from '../../ui/buttons/Button';
import Checkbox from '../../ui/checkbox/Checkbox';
// NOSONAR
import { handleCustomQuestionsUpdate } from '../../pages/moduleSelection/ModuleSelection.utility';
import DPInstructionTablePreview from '../dpInstructionTablePreview/DPInstructionTablePreview';

/**
 * @class ApproveCustomQuestion
 * @param {Number} customQuestionId value of customQuestionId property on the local question object.
 *
 * @description This component allows the user to to update a question's legal and translation approval statuses as well as approve or deny it.
 *
 * The **ApproveCustomQuestion** component is used in the **ModuleSelection** component.
 *
 * @returns {JSX} Component containing tabs for each language variant available for a local question, as well as the question text, answer, translation and legal approval status. The component allows the user to decide whether a custom question is to be approved or denied.
 */
const ApproveCustomQuestion = ({ customQuestionId }) => {
  const { isLoading, customQuestions, error } = useSelector(
    (store) => store.customQuestions
  );
  const { wave } = useSelector((store) => store.wave);
  const dispatch = useDispatch();

  const [languages, setLanguages] = useState([]);
  const [customQuestion, setCustomQuestion] = useState({});
  const [currentLanguageId, setCurrentLanguageId] = useState(-1);
  const [texts, setTexts] = useState({});
  // NOSONAR
  const [comment, setComment] = useState('');
  const [isLegalApproved, setIsLegalApproved] = useState(false);

  const handleLanguage = (languageId) => {
    setCurrentLanguageId(languageId);
  };

  /**
   * @function
   * @memberof ApproveCustomQuestion
   * @param {Boolean} checked Checked value of the checkbox.
   *
   * @description When the user clicks on the translation approval checkbox it updates the 'customQuestion' state variable, and sets the value of its 'isTranslationApproved' key to the value of the 'checked' argument the function receives.
   */
  const handleTranslationApproval = (checked) => {
    const obj = {
      lnagSpecific: {
        languageId: currentLanguageId,
        isTranslationApproved: checked,
      },
    };
    setCustomQuestion(updateCustomQuestion(customQuestion, obj));
  };

  /**
   * @function
   * @memberof ApproveCustomQuestion
   * @param {Boolean} checked Checked value of the checkbox.
   *
   * @description When the user clicks on the legal approval checkbox it updates the 'customQuestion' state variable, and sets the value of its 'isLegalApproved' key to the value of the 'checked' argument the function receives. It also  updates the value of the 'isLegalApproved' state variable to the value of the 'checked' argument.
   */
  const handleLegalApproval = (checked) => {
    setCustomQuestion(
      updateCustomQuestion(customQuestion, { isLegalApproved: checked })
    );
    setIsLegalApproved(checked);
  };

  /**
   * @function
   * @memberof ApproveCustomQuestion
   * @param {Requested' | 'Approved'} status 'Requested' or 'Approved', depending on the button the user clicks.
   *
   * @description When the user clicks on the 'Deny', 'Save' or 'Approve' buttons it dispatches 'updateCustomQuestion' to update the status of the custom question to either 'Requested' or 'Approved', depending on which button is clicked..
   */
  const handleSubmit = (status) => {
    const Q = updateCustomQuestion(customQuestion, { status: status });
    dispatch(UpdateCustomQuestion(Q));
  };

  /**
   * @method useEffect
   * @memberof ApproveCustomQuestion
   * @param {Array} customQuestions Array containing all local questions for this wave.
   * @param {Number} customQuestionId Id of the local question whose contents we are currently rendering.
   *
   * @description  When the current 'customQuestionId' changes, and is a key in 'customQuestions', it calls the handleCustomQuestionsUpdate function.
   * @see {@link '../../pages/moduleSelection/ModuleSelection.utility'}, lines 142-161
   *
   * The effect is executed every time the values of either 'customQuestionId' or 'customQuestions' updates ( which is when the user clicks on a different local question ). This effect is executed only if the length of the 'customQuestions' array is greater than 1, and 'customQuestionId' is a key in the 'customQuestions' array.
   */
  useEffect(() => {
    handleCustomQuestionsUpdate(
      customQuestions,
      customQuestionId,
      languages,
      wave,
      setLanguages,
      setCurrentLanguageId,
      setCustomQuestion
    );
  }, [customQuestions, customQuestionId]);

  /**
   * @method useEffect
   * @memberof ApproveCustomQuestion
   * @param {Number} currentLanguageId ID of the language whose question text and answers are currently being displayed.
   * @param {Array} customQuestion.question.questionTextFields Array containing all language versions of the question being viewed.
   *
   * @description  If the value of 'currentLanguageId' is valid, it sets the state of 'texts' to the appropriate item in the 'customQuestion.question.questionTextFields' array based on the question languageId. This effect allows the component to display the correct question text and answers when switching between languages.
   *
   * The effect is executed every time the values of either 'currentLanguageId' or 'customQuestion.question.questionTextFields' updates ( which is when the user switches between languages ). This effect is executed only if 'currentLanguageId' is not equal to -1.
   */
  useEffect(() => {
    if (currentLanguageId !== -1) {
      setTexts(
        customQuestion.question.questionTextFields.find((q) => {
          return q.languageId === currentLanguageId;
        })
      );
    }
  }, [currentLanguageId, customQuestion.question]);
    
  return (
    <div>
      {isLoading && <div>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      {customQuestion && customQuestion.question && (
        <div className={styles.form}>
          <div className={styles.title}>                     
                      {GetFormattedLocalQuestionName(customQuestion?.customNamePrefix, customQuestion?.question?.name)}
          </div>
          <div className={styles.tabContainer}>
            <Tabs
              labelsTabs={languages}
              idCurrent={currentLanguageId}
              handleOnClick={handleLanguage}
            ></Tabs>
          </div>
          <div className={styles.tabContent}>
           <div
               className={`${styles.formControl} ${styles.questionTextControl}`}
           >
               <label>Variable code</label>
               <p dangerouslySetInnerHTML={{ __html: customQuestions[customQuestionId].question?.variableCode }} id="variableCode"/>
           </div>
           <div
               className={`${styles.formControl} ${styles.questionTextControl}`}
           >
               <label>Question type</label>
               <p dangerouslySetInnerHTML={{__html: customQuestions[customQuestionId].question.questionType?.name}} id="questionType" />
           </div>

           <div
               className={`${styles.formControl} ${styles.questionTextControl}`}
           >
               <label>Base</label>
               <p dangerouslySetInnerHTML={{ __html: customQuestions[customQuestionId].question.base }} id="base" />
           </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Question text</label>
              <p dangerouslySetInnerHTML={{ __html: texts.questionText }} />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Answers</label>
              <p dangerouslySetInnerHTML={{ __html: texts.answerText }} />
            </div>

            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Scripting instructions</label>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    customQuestions[customQuestionId].scriptingInstruction,
                }}
              />
            </div>

            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>DP Table Instructions</label>
              
            </div>

            {
                customQuestions[customQuestionId].dpInstructions?.map((item, index) => {
                    return (<DPInstructionTablePreview id={item.dpInstructionsId} index={index} item={item} />)
                })
            }


            <div className={styles.CheckboxContainer}>
              <Checkbox
                id="translationApproval"
                label={'Translation has been approved'}
                checked={texts.isTranslationApproved}
                changed={(checked, id) => handleTranslationApproval(checked)}
              ></Checkbox>
            </div>
          </div>
          <div>
            <div className={styles.CheckboxContainer}>
              <Checkbox
                id="legalApproval"
                label={'Question and translations have been legally approved'}
                checked={isLegalApproved}
                changed={(checked, id) => handleLegalApproval(checked)}
              ></Checkbox>
            </div>
          </div>
          <div className={styles.btnContainer}>
            <Button
              type="secondary"
              handleOnClick={() => handleSubmit('Requested')}
            >
              Deny
            </Button>
            <Button
              type="primary"
              handleOnClick={() => handleSubmit('Defined')}
            >
              Save
            </Button>
            <Button
              type="primary"
              handleOnClick={() => handleSubmit('Approved')}
              disabled={!isLegalApproved}
            >
              Approve
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ApproveCustomQuestion.propTypes = {
  customQuestionId: PropTypes.number.isRequired,
};

ApproveCustomQuestion.defaultProps = {
  customQuestionId: -1,
};

export default ApproveCustomQuestion;
