import React, { useState } from "react";
import Button from "../buttons/Button";
import styles from './WaveCopy.module.css'
import SearchableDropdown from "../SearchableDropdown/SearchableDropdown";
import { useDispatch } from "react-redux";

function WaveCopyPopup ({handleClose, waves, getSourceWaveId}) {
  const [showWaveList, setShowWaveList] = useState(false)
  //this should be the list of waves, to come from DB
  const itemList = waves
  console.log(itemList, 'list of waves to copy.............')
  const handleClick = (btn) => {
    if(btn === 'yes' && !showWaveList){ 
      setShowWaveList(true)
    }
    else if(btn === 'yes' && showWaveList){
      handleClose('copy')
    }
    else if (btn === 'no' || btn === 'close'){
      handleClose('cancel')
    }   
  }

  const handleDropdownInput = (val) => {
    console.log('handledropdowninput was executed', val, '---', val==='', itemList)
    if (val != '') {
      getSourceWaveId(val)
    }
  }

 return(
    <div>      
      <div className={styles.BtnCloseWrapper}>
        <Button handleOnClick={()=>handleClick('close')} type='close' />
      </div>
      <div className={styles.Title}>
        <h4>{showWaveList?'Wave list':'Copy wave'}</h4>
      </div>
      {itemList.length === 0 
      ? 
        <div  className={styles.Message}>Please note, there are currently no available waves to copy. Please make your module selection and add local questions.</div>       
      :
        <>
        <div className={styles.Message}>
          {
            showWaveList?
            <div>
              <SearchableDropdown id={'waveCopySelect'} waveList={itemList} handleInputProp={(val,id)=>handleDropdownInput(val)}/>
            </div>:
            <div>
            You are about to copy an existing wave, which will override any selections made for this wave.{<br/>}Are you sure you want to continue?
          </div> }       
        </div>      
        <div className={styles.BtnWrapper}>
          <Button handleOnClick={()=>handleClick('no')} type='secondary' >{showWaveList?'Cancel':'No'}</Button>
          <Button handleOnClick={()=>handleClick('yes')} type='primary' >{showWaveList?'Copy':'Yes'}</Button>
        </div>
      </>}
      
    </div>
 )
}

export default WaveCopyPopup