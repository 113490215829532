import React from 'react';
import ReactDOM from 'react-dom';
import Notification, { showToast } from './Notification/Notification';
import createContainer from './createContainer/Container';

const containerElement = createContainer();

  ReactDOM.render(
    <Notification/>,
     containerElement
   );


export function toastNotification(children, autoClose = true) {
  return showToast(children, autoClose);
}
