import { cloneDeep as _cloneDeep, isArray } from 'lodash';
import { toastNotification } from '../components/ui/toastNotification/ToastNotification';

export const updateObject = (oldObject, updatedPorperties) => {
  const old = _cloneDeep(oldObject);
  const upd = _cloneDeep(updatedPorperties);
  return {
    ...old,
    ...upd,
  };
};
export const sortCountriesOrder = (countries) => {
  return countries.sort((a, b) => {
    return a.name - b.name;
  });
};

export const customQuestionloiContributionInMinutes = 0.3;

const buildOrederedList = (modList) => {
  return modList
    .sort((a, b) => a.sequence - b.sequence)
    .map((x) => x.surveyModuleId);
};

const buildModuleGroupList = (modList, newObj) => {
  const groupList = {};
  modList
    .sort((a, b) => a.sequence - b.sequence)
    .forEach((module) => {
      if (module.groupModules.length > 0) {
        module.groupModules.forEach((group) => {
          if (groupList.hasOwnProperty(group.groupId)) {
            groupList[group.groupId].moduleIds.push(module.surveyModuleId);
            if (
              isNotMandatoryGroupThisWave(group) ||
              (checkMandatoryChoiceGroup(groupList[group.groupId]) &&
                newObj.moduleList[module.surveyModuleId].selected)
            ) {
              groupList[group.groupId].valid = true;
            }
          } else {
            groupList[group.groupId] = {
              groupId: group.groupId,
              rule: group.group.rule.name,
              moduleIds: [module.surveyModuleId],
            };
            if (isNotMandatoryGroupThisWave(group)) {
              groupList[group.groupId].valid = true;
            } else if (checkMandatoryChoiceGroup(groupList[group.groupId])) {
              groupList[group.groupId].valid =
                newObj.moduleList[module.surveyModuleId].selected;
            }
          }
        });
      }
    });
  return groupList;
};
const isNotMandatoryGroupThisWave = (group) => {
  return (
    isMandatoryChoiceSingleWaveGroupRule(group?.group?.rule?.name) &&
    !group?.group?.mandatoryOncePerYear &&
    !group?.group?.mandatoryFirstWave
  );
};
export const buildModuleState = (isBoosterWave, modList, selectedList) => {
  const newObj = {};
  if (selectedList === '') {
    newObj.moduleList = modList.reduce(
      (obj, item) => (
        (obj[item.surveyModuleId] = {
          ..._cloneDeep(item),
          selected: checkBoosterWaveNoMandatoryRule(
            isBoosterWave,
            item.mandatory
          ),
          mandatory: checkBoosterWaveNoMandatoryRule(
            isBoosterWave,
            item.mandatory
          ),
        }),
        obj
      ),
      {}
    );
  } else {
    newObj.moduleList = modList.reduce(
      (obj, item) => (
        (obj[item.surveyModuleId] = {
          ..._cloneDeep(item),
          selected: false,
          mandatory: checkBoosterWaveNoMandatoryRule(
            isBoosterWave,
            item.mandatory
          ),
        }),
        obj
      ),
      {}
    );
    selectedList.forEach(
      (element) => (newObj.moduleList[element.surveyModuleId].selected = true)
    );
  }
  newObj.groupList = buildModuleGroupList(modList, newObj);
  newObj.orderedList = buildOrederedList(modList);
  return newObj;
};

export const checkBoosterWaveNoMandatoryRule = (
  isBoosterWave,
  isModuleMandatory
) => {
  return isBoosterWave ? false : isModuleMandatory;
};
export const isBoosterWave = (wave) => {
  return wave?.booster ? true : false;
};
export const buildSelectedModulePayload = (modList) => {
  const newArr = Object.values(modList).map((obj) =>
    (({ surveyModuleId, selected }) => ({
      surveyModuleId,
      isSelected: selected,
    }))(obj)
  );
  return newArr;
};

export const updateCustomQuestion = (
  question,
  propsObj,
  waveid = null,
  waveLanguages = null,
  dpInstructions = null
) => {
  let newObj = {};
  if (question === null) {
    const newTextFields = waveLanguages.map((language) => {
      return {
        questionTextFieldId: 0,
        questionText: '',
        answerText: '',
        isTranslationApproved: false,
        questionId: 0,
        languageId: language.languageId,
      };
    });
    let newdpInstructions = [];
    if (dpInstructions !== null) {
      newdpInstructions = dpInstructions.map((dpInstructionData) => {
        return {
          dpInstructionsId: 0,
          tableName: dpInstructionData.tableName,
          tableBase: dpInstructionData.tableBase,
          tableTitle: dpInstructionData.tableTitle,
          dpInstruction: dpInstructionData.dpInstruction,
          dpAdditionalInformation: dpInstructionData.dpAdditionalInformation,
          customQuestionId: dpInstructionData.customQuestionId,
        };
      });
    }

    newObj = {
      customQuestionId: 0,
      customNamePrefix: '',
      loiContributionInMinutes: customQuestionloiContributionInMinutes,
      isLegalApproved: false,
      requestDescription: '',
      scriptingInstruction: '',
      dpInstruction: '',
      waveId: waveid,
      customQuestionStatus: {
        status: 'New',
      },
      questionId: 0,
      question: {
        questionId: 0,
        name: '',
        surveyModuleId: null,
        questionTextFields: newTextFields,
      },
      comments: [],
      dpInstructions: newdpInstructions,
    };
  } else {
    newObj = _cloneDeep(question);
  }
   
  Object.keys(propsObj).forEach((key, ind) => {
    if (newObj.hasOwnProperty(key)) {
      newObj[key] = propsObj[key];
    }

    if (newObj.question.hasOwnProperty(key)) {
      newObj.question[key] = propsObj[key];
    }
    if (newObj.customQuestionStatus.hasOwnProperty(key)) {
      newObj.customQuestionStatus[key] = propsObj[key];
    }
    if (newObj?.customNamePrefix?.hasOwnProperty(key)) {
      newObj.customNamePrefix[key] = propsObj[key];
    }
    if (propsObj[key].hasOwnProperty('languageId')) {
      let index = -1;
      newObj.question.questionTextFields.forEach((val, ind) => {
        if (val.languageId === propsObj[key]['languageId']) {
          index = ind;
        }
      });
      if (index !== -1) {
        newObj.question.questionTextFields[index] = updateObject(
          newObj.question.questionTextFields[index],
          propsObj[key]
        );
      } else {
        newObj.question.questionTextFields.push({ ...propsObj[key] });
      }
    }
    if (key === 'comment') {
      newObj.comments.push(propsObj.comment);
    }
  });
  return newObj;
};
export const GetPreviewData = (data, sortKey, filterKey) => {
  if (data != undefined) {
    let previewData = Object.values(data).length ? Object.values(data) : [];
    if (previewData && previewData.length) {
      if (sortKey) {
        previewData = previewData.sort((a, b) => a[sortKey] - b[sortKey]);
      }
      if (filterKey) {
        previewData = previewData.filter((item) => item[filterKey] === true);
      }
    }

    return previewData;
  }
};

export const updateTitle = (text) => {
  document.title = `GfK Author${text}`;
};

export const checkUXPrivileges = (uxPrivileges, privilege) => {
  let name = '';

  switch (privilege) {
    case 'editSelection':
      name = 'Edit module selection';
      break;
    case 'commentSelection':
      name = 'Comment module selection';
      break;
    case 'submitCustomQ':
      name = 'Submit custom question';
      break;
    case 'approveCustomQ':
      name = 'Approve custom question';
      break;
    case 'commentCustomQ':
      name = 'Comment on custom question';
      break;
    case 'editWave':
      name = 'Edit wave';
      break;
    case 'approveModule':
      name = 'Approve module selection';
      break;
    case 'buildCustomQ':
      name = 'Build custom question';
      break;
    case 'readOnly':
      name = 'Read Only';
      break;
    case 'OverwriteLoiLimit':
      name = 'OverwriteLoiLimit';
      break;
    case 'submitModuleSelection':
      name = 'Submit module selection';
      break;
    default:
      name = 'default';
  }

  const filtered = uxPrivileges.filter((p) => {
    return p.name.toLowerCase() === name.toLowerCase();
  });

  return filtered.length > 0;
};

export const notificationToShortName = (notificationName) => {
  let shortName;

  switch (notificationName) {
    case 'Module & local questions selections are due to be made':
      shortName = 'makeSelection';
      break;
    case 'Module choices and local question selections have been made and are awaiting approval (GfK) approval':
      shortName = 'approveSelection';
      break;
    case 'JTI make a query/request support regarding local questions':
      shortName = 'supportCustomQ';
      break;
    case 'GfK approve local questions':
      shortName = 'approvedCustomQ';
      break;
    case 'JTI requests contact/support re ad hoc research':
      shortName = 'supportAdHoc';
      break;
    case 'Reports are ready to review':
      shortName = 'reviewReports';
      break;
    case 'Testlink is ready to review':
      shortName = 'reviewTest';
      break;
    case 'GfK rejected module selection and local questions':
      shortName = 'rejectSelection';
      break;
    default:
      shortName = '';
  }

  return shortName;
};

export const getFormattedDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[date.getMonth()];
  return month && year ? `${month} ${year}` : '';
};

export const getFieldWorkStartDate = (wave) => {
  let waveFieldworkObj = wave?.waveProcesses?.find(
    (waveProcess) => waveProcess?.process?.name === 'Fieldwork'
  );
  return waveFieldworkObj ? waveFieldworkObj.startDate : '';
};

export const getWaveNameWithStartDate = (wave) => {
  const waveName = `${wave.name} | ${getFormattedDate(
    getFieldWorkStartDate(wave)
  )}`;
  return wave?.name ? waveName : '';
};

export const parseAnswer = (answerText) => {
  if (!answerText) return false;

  try {
    const pAnswerText = JSON.parse(answerText);
    let type = '';
    if (isArray(pAnswerText)) {
      type = 'array';
    } else type = typeof pAnswerText;
    return { value: pAnswerText, type: type };
  } catch (error) {
    return { value: answerText, type: typeof answerText, error: true };
  }
};

export const shouldShowTooltip = (wave, groupData, mandatoryChoiceGroup) => {
  return (
    !isBoosterWave(wave) &&
    groupData.firstMandatory &&
    (groupData.mandatoryGroupRuleOncePerYear === true ||
      groupData.mandatoryOncePerYear === true ||
      checkMandatoryChoiceGroup(mandatoryChoiceGroup))
  );
};

export const handleMandatoryIcon = (
  groupData,
  mandatoryChoiceGroup,
  mandatoryIconErr,
  savedModuleSelection
) => {
  if (
    groupData.mandatoryGroupRuleOncePerYear ||
    groupData.mandatoryGroupRuleFirstWave
  ) {
    if (groupData.mandatoryGroupThisWave) {
      if (!mandatoryChoiceGroup.valid) {
        return mandatoryIconErr ? 'Error' : 'Warning';
      } else {
        return 'Ok';
      }
    } else {
      return 'Warning';
    }
  } else if (groupData.mandatoryOncePerYear) {
    if (groupData.mandatoryThisWave) {
      return 'Ok';
    } else {
      return 'Warning';
    }
  } else if (checkMandatoryChoiceGroup(mandatoryChoiceGroup)) {
    if (!mandatoryChoiceGroup.valid) {
      return savedModuleSelection ? 'Error' : 'Warning';
    } else if (
      !isMandatoryChoiceSingleWaveGroupRule(mandatoryChoiceGroup.rule)
    ) {
      return 'Ok';
    }
  }
  return null;
};

export const setBorderStyle = (a, b) => {
  if (b) {
    switch (a) {
      case 'Ok':
        return 'Green';
      case 'Warning':
        return 'Orange';
      case 'Error':
        return 'Red';

      default:
        return null;
    }
  }
  return null;
};

export const updateWaveProcess = (
  waveProcesses,
  updatedProcessName,
  updates
) => {
  return waveProcesses.map((proc) => {
    if (proc.process.name === updatedProcessName) {
      return {
        ...proc,
        ...(updates.process ? {} : updates),
        process: {
          ...proc.process,
          ...(updates.process || {}),
        },
      };
    }
    return proc;
  });
};

export const showToaster = (toast, autoClose) => {
  toastNotification({ title: toast.title, message: toast.message }, autoClose);
};

/**
 * @function
 * @param {String} status
 * @returns {string} a descriptive string corresponding to the status argument.
 */
export const getStatus = (status) => {
  switch (status) {
    case 'New':
      return 'New, undefined question.';
    case 'Requested':
      return 'Question definition or updates have been requested!';
    case 'Defined':
      return 'Question awaiting approval!';
    case 'Approved':
      return 'Question is approved!';
    default:
      return;
  }
};

export const getProjectCountries = (projects) => {
  let projectCountries = [];
  if (projects) {
    projectCountries = Object.values(projects).reduce(
      (projectCountries, project) => {
        if (project?.projectCountries?.length) {
          return projectCountries.concat(project.projectCountries);
        }
        return projectCountries;
      },
      []
    );
  }
  return projectCountries;
};
/**
 * @function
 * @param {Object} group
 * @returns {Boolean} returns true if group.rule value is same as mandatoryChoice or mandatoryChoiceOncePerYear or mandatoryChoice rule, else false.
 */
export const checkMandatoryChoiceGroup = (group) => {
  return (
    group?.rule === 'mandatoryChoiceOncePerYear' ||
    group?.rule === 'mandatoryChoiceFirstWave' ||
    group?.rule === 'mandatoryChoice'
  );
};
/**
 * @function
 * @param {Object} group
 * @returns {Boolean} returns true if ruleName value is same as  mandatoryChoiceOncePerYear or mandatoryChoiceFirstWave rule, else false.
 * @description All rule mentioned in this method are only required in one wave only.
 */
const isMandatoryChoiceSingleWaveGroupRule = (ruleName) => {
  return (
    ruleName === 'mandatoryChoiceOncePerYear' ||
    ruleName === 'mandatoryChoiceFirstWave'
  );
};
export const GetMessagesObject = (messageArray) => {
  return messageArray.reduce((messagesObj, message) => {
    messagesObj[message.messageKey] = message;
    return messagesObj;
  }, {});
};

export const getMessageWithReplacedKeyValuePairs = (
  message,
  replaceItems = []
) => {
  let updatedMessage = message;
  replaceItems.forEach((item) => {
    updatedMessage = updatedMessage?.replace(
      new RegExp(`{{${item.key}}}`, 'g'),
      item.value
    );
  });
  return updatedMessage;
};

export const getMessageWithReplacedKeyValue = (message, key, value) => {
  return message?.replace(new RegExp(`{{${key}}}`, 'g'), value);
};

export const GetFormattedLocalQuestionName = (
    customNamePrefix,customQuestionName
) => {   
    return customNamePrefix + '_' + customQuestionName;   
};