import ModuleContent from '../../containers/moduleContent/ModuleContent';
import { checkUXPrivileges } from '../../../utility/utility';
import QuestionSubmit from '../../ui/QuestionSubmit/QuestionSubmit';
import QuestionForm from '../../containers/questionForm/QuestionForm';
import ApproveCustomQuestion from '../../containers/approveCustomQuestion/ApproveCustomQuestion';
import QuestionPreview from '../../containers/questionPreview/QuestionPreview';
import React from 'react';
import ToggleComponent from '../../ui/ToggleComponent/ToggleComponent';

/**
 * @function
 * @memberof ModuleSelection
 * @param {String} type possible values: 'module', 'customQ', and 'blank'. The type is 'module' when a module is clicked, 'customQ' when a local question is clicked, and 'blank' when a local questions is removed.
 * @param {Number} id For modules this is the value of 'surveyModuleId', for local questions this is the value of 'customQuestionId'.
 * @param {String} name The name of the user.
 * @param {Array} uxPrivileges Array of objects containing all ux privileges the user has depending on their role.
 * @param {Object} customQuestions Object containing key-value pairs, where the key is the value of 'customQuestionId', and the value is the customQuestion object.
 * @param {Function} questionclickHandler On click of a module / local question in the SidePanel it renders the contents of the module / local question in the ModuleContent component.
 * @param {String} moduleSelectionStatus String containing the status of the wave's module selection. Possible values are 'Pending', 'In progress', and 'Finished';
 * @description The function serves the purpose of rendering the appropriate content in the 'ModuleSelection' component. Based on the arguments it receives it will render the following component:
 *  - If the 'type' argument is equal to 'module' it will render the 'ModuleContent' component inside the 'ModuleSelection' component.
 *  - If the 'type' argument is equal to 'blankPage' it will not render anything in the 'ModuleSelection' component.
 *  - If the 'type' argument is equal to 'customQ' it will render the following component:
 *    - 'QuestionPreview' - If the status of this wave's module selection is set to 'Finished'.
 *    - 'QuestionForm' - If the user's 'uxPrivileges' contains 'buildCustomQ', and the final selection has been submitted by the user.
 *    - 'QuestionSubmit' - If the user's 'uxPrivileges' contains'submitCustomQ', and the final selection has not been submitted by the user.
 *    - 'ApproveCustomQuestion' - If the user's 'uxPrivileges' contains 'approveCustomQ'.
 *    - 'QuestionPreview' - When previewing the wave's module selection.
 *
 * If a user has multiple privileges, they will be prioritized in the order above ( so if a user has both 'buildCustomQ', and 'submitCustomQ', the latter will be ignored, and the component assoiated with the first privilege will be rendered ).
 *
 * @returns {JSX} based on the parameters this function receives it returns a specific component.
 * The components it can return are: 'ModuleContent', 'QuestionForm', 'QuestionSubmit', 'ApproveCustomQuestion', and 'QuestionPreview'. It can also return null, but this happens only when a local question is removed.
 */
export const getRelevantPage = (
  type,
  id,
  name,
  uxPrivileges,
  customQuestions,
  questionclickHandler,
  moduleSelectionStatus
) => {
  console.log('module log', type);
  if (type === 'module') {
    return (
      <>
        <ToggleComponent />
        <ModuleContent moduleId={id} isPreview={false} />
      </>
    );
  }
  if (type === 'customQ') {
    if (moduleSelectionStatus === 'Finished') {
      // displays the contents of the custom question in preview mode if the
      // status of the current wave's Module Selection is set to 'Finished'
      return <QuestionPreview customQuestionId={id}></QuestionPreview>;
    } else if (
      checkUXPrivileges(uxPrivileges, 'buildCustomQ') &&
      (customQuestions[id].customQuestionStatus.status === 'Requested' ||
            customQuestions[id].customQuestionStatus.status === 'Defined' ||
            customQuestions[id].customQuestionStatus.status === 'New')
    ) {
      return (
        <QuestionForm
          customQuestionId={id}
          clickHandler={questionclickHandler}
        />
      );
    } else if (
      checkUXPrivileges(uxPrivileges, 'submitCustomQ') &&
      (customQuestions[id].customQuestionStatus.status === 'New' ||
        customQuestions[id].customQuestionStatus.status === 'Requested')
    ) {
      return <QuestionSubmit key={id} customQuestionId={id}></QuestionSubmit>;
    } else if (
      checkUXPrivileges(uxPrivileges, 'approveCustomQ') &&
      customQuestions[id].customQuestionStatus.status === 'Defined'
    ) {
      return (
        <ApproveCustomQuestion
          customQuestionId={id}
          userName={name}
        ></ApproveCustomQuestion>
      );
    } else {
      return <QuestionPreview customQuestionId={id}></QuestionPreview>;
    }
  }
  if (type === 'blankPage') {
    //handles the case when a custom question is deleted
    return null;
  }
};

/**
 * @function
 * @memberof ModuleSelection
 * @param {Object} wave Object containing all information pertaining to this wave.
 * @param {Function} setLanguages Setter function called using 'myLanguages' as an argument.
 * @param {Function} setCurrentLanguageId Setter function that accepts 'currentLanguageId' as its argument.
 * @description Accesses 'wave.waveLanguages', maps over it, and assigns an array of languages to the variable 'myLanguages'. Each item in the array is an object containing the language's 'name', and 'id'. Calls the 'setLanguages' function with the value of 'myLanguages'. Calls the 'setDefaultLanguage' function to update the default language for the wave.

*/
export const setLanguageSettings = (
  wave,
  setLanguages,
  setCurrentLanguageId
) => {
  const myLanguages = wave.waveLanguages.map((language) => {
    return {
      name: language.language.isoCode.toUpperCase(),
      id: language.language.languageId,
    };
  });
  setLanguages(myLanguages);
  setDefaultLanguage(myLanguages, setCurrentLanguageId);
};

/**
 * @function
 * @memberof ModuleSelection
 * @param {Array} myLanguages Array of languages available for this wave.
 * @param {Function} setCurrentLanguageId Setter function that accepts 'currentLanguageId' as its argument.
 * @description Checks if 'EN' is an available language in the 'myLanguages' array. If it it, it sets 'currentLanguageId' to the id of the English language, using the 'setCurrentLanguageId' function. If not it sets 'currentLanguageId' as the id of the first language in the 'myLanguages' array. The value of the default language will determine in what language the question details will be rendered.
 */
export const setDefaultLanguage = (myLanguages, setCurrentLanguageId) => {
  // Set default EN
  const languageEN = myLanguages.find((lang) => {
    return lang.name === 'EN';
  });

  if (languageEN === undefined) {
    const myLanguageId = myLanguages[0].id;
    setCurrentLanguageId(myLanguageId);
  } else {
    setCurrentLanguageId(languageEN.id);
  }
};

/**
 * @memberof ModuleSelection
 * @description  When the current 'customQuestionId' changes, and is a key in 'customQuestions' it renders the contents of the appropriate question. The function also calls 'setLanguageSettings' if languages.length is greater than 1.
 * This happens when the user clicks on a local question in the sidebar, switching the contents of the local question that was rendered previously, to the one that was clicked.
 */

export const handleCustomQuestionsUpdate = (
  customQuestions,
  customQuestionId,
  languages,
  wave,
  setLanguages,
  setCurrentLanguageId,
  setCustomQuestion
) => {
  if (
    Object.keys(customQuestions).length > 0 &&
    Object.keys(customQuestions).includes(customQuestionId.toString())
  ) {
    const myCustomQuestion = customQuestions[customQuestionId];
    setCustomQuestion(myCustomQuestion);  
      
    if (languages.length === 0) {
      setLanguageSettings(wave, setLanguages, setCurrentLanguageId);
    }
  }
};


export const handleCustomQuestionsAndDPInstructionsUpdate = (
    customQuestions,
    customQuestionId,
    languages,
    wave,
    setLanguages,
    setCurrentLanguageId,
    setCustomQuestion,
    loadDPInstructions 
) => {
    handleCustomQuestionsUpdate(customQuestions,
        customQuestionId,
        languages,
        wave,
        setLanguages,
        setCurrentLanguageId,
        setCustomQuestion);
    if (
         loadDPInstructions &&
        Object.keys(customQuestions).length > 0 &&
        Object.keys(customQuestions).includes(customQuestionId.toString())
    ) { 
            const myCustomQuestion = customQuestions[customQuestionId]; 
            loadDPInstructions([...myCustomQuestion?.dpInstructions]);
               
    }
};