import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './checkbox.module.css';

function Checkbox(props) {
  const [checked, setChecked] = useState(
    props.mandatory || props.checked || false
  );
  const getStyling = () => {
    let styleList = `${styles.checkboxDiv} ${props.active ? styles.Active : null}`
    if (props.additionalStyling?.length > 0){
      props.additionalStyling.forEach(x => {
        styleList += ` ${styles[x]}`    
      });
    }
    return styleList
  } 

  let checkClick = () => {
    props?.clicked(props.id);
    if (!props.mandatory && !props.disabled) {
      props.changed(!checked, props.id);
      setChecked(!checked);
    }
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked, props.forceRerender]);

  return (
    <div
      data-testid="checkbox"
      onClick={checkClick}
      className={getStyling()}
    >
      <input
        type="checkbox"
        data-testid={props?.testId || 'checkbox-input'}
        checked={checked || false}
        disabled={props.mandatory || props.disabled}
        onChange={(e) => {
          console.log(e);
        }}
        className={styles.checkboxInput}
        aria-checked={checked || false}
      ></input>
      <div
        className={`${props.radio ? styles.Radiobutton : styles.Checkbox} ${
          props.mandatory || props.disabled ? styles.Disabled : null
        } ${checked || props.checked ? styles.Checked : null}`}
      ></div>
      <div role="label" className={styles.Label}>
        {props.label}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  changed: PropTypes.func.isRequired,
  clicked: PropTypes.func,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  active: PropTypes.bool,
  radio: PropTypes.bool
};

Checkbox.defaultProps = {
  id: -1,
  label: '',
  changed: (checked, id) => {
    console.log(checked, id);
  },
  clicked: (id) => {
    console.log(id);
  },
  mandatory: false,
  disabled: false,
  checked: false,
  active: false,
  radio: false
};

export default Checkbox;
