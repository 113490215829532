import React from 'react';
import PropTypes from 'prop-types';
import styles from './LengthOfInterview.module.css';

const LengthOfInterview = ({ number, threshold, children }) => {
  return (
    <h4
      className={        
        (number > threshold)? `${styles.LOI_aboveThreshold} ${styles.LOI}` : styles.LOI
      }
    >
      {children[0]} {number} {children[1]}
    </h4>
  );
};

LengthOfInterview.propTypes = {
  number: PropTypes.number.isRequired,
  threshold: PropTypes.number,
  children: PropTypes.array,
};

LengthOfInterview.defaultProps = {
  number: 0,
  threshold: null,
  children: ['Length of interview:', 'minutes'],
};

export default LengthOfInterview;
