import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './WaveSelection.module.css';
import CountryMenu from '../../containers/countryMenu/CountryMenu';
import WaveStatus from '../../containers/waveStatus/WaveStatus';

import { useParams } from 'react-router-dom';
import { updateTitle, checkUXPrivileges } from '../../../utility/utility';
import Logo from '../../ui/logo/Logo';

import { ClearWave, updateCrumbs, ClearModules } from '../../../store/actions';

const WaveSelection = () => {
  const [currentWaveId, setCurrentWaveId] = useState(-1);
  const [currentCountryId, setCurrentCountryId] = useState(-1);
  const [scrollCountryId, setScrollCountryId] = useState(-1);
  const { countries, isLoading, error } = useSelector((state) => state.wave);
  const { uxPrivileges } = useSelector((state) => state.app.user.user.role);
  const { dataPrivileges } = useSelector((state) => state.app.user.user.role);
  const assignedCountries = useSelector(
    (state) => state.app.user.user.userCountrys
    );      
  const dispatch = useDispatch();
  let { country } = useParams();
  const showCountryMenu = useRef(false);

  useEffect(() => {
    dispatch(ClearWave());
    dispatch(ClearModules());

    updateTitle('');
  }, []);

    useEffect(() => {     

        
    if (assignedCountries.length > 1 || dataPrivileges.filter(p => p.name.toLowerCase() === "all waves in all countries").length > 0) {         
      showCountryMenu.current = true;
    } else {
        showCountryMenu.current = false;
    }
    }, [assignedCountries, dataPrivileges]);

  useEffect(() => {
    dispatch(
      updateCrumbs([
        { text: 'Waves', link: '/' },
        {
          text: country,
          link: `waves/${country}`,
        },
      ])
    );
  }, [country]);
  useEffect(() => {
    if (country !== undefined && countries && countries.length > 0) {
      let tempCntry = countries.filter(
        (cntry) => cntry.name.toLowerCase() === country.toLowerCase()
      )[0]?.countryId;
      setScrollCountryId(tempCntry);
      setCurrentCountryId(tempCntry);
    }
  }, [countries]);

  const statusToStatusType = (status) => {
    switch (status) {
      case 'Finished':
        return 'success';
      case 'In progress':
        return 'progress';
      case 'Failed':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <div
      className={`${styles.Page} ${
        showCountryMenu.current ? '' : styles.Fullscreen
      }`}
    >
      <Logo />
      {isLoading && <div className={styles.loading}>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      {showCountryMenu.current ? (
        <CountryMenu
          countries={countries}
          onClickWave={setCurrentWaveId}
          onClickCountry={setCurrentCountryId}
          currentCountryId={currentCountryId}
          scrollTo={scrollCountryId}
        />
      ) : null}
      <WaveStatus
        countries={countries}
        setCurrentWaveId={setCurrentWaveId}
        currentWaveId={currentWaveId}
        statusToStatusType={statusToStatusType}
        scrollTo={currentCountryId}
        editWavesPrivilege={checkUXPrivileges(uxPrivileges, 'editWave')}
      />
    </div>
  );
};

export default WaveSelection;
