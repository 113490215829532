export const calcloi = (modules, customQuestions) => {
  let loi = 0;
  const selectedModules = Object.values(modules)
    .filter((module) => module.selected)
    .reduce((a,b) => a?.loiContributionInMinutes || a + b.loiContributionInMinutes, 0)
  
  const customQuestion = Object.values(customQuestions)
    .reduce((a,b) => a?.loiContributionInMinutes || a + b.loiContributionInMinutes, 0)

  loi = Math.round((selectedModules + customQuestion) * 10) / 10
  return loi;
}