import React, { useEffect, useState } from 'react';
import styles from './Tooltip.module.css';

export const Tooltip = ({ content, icon }) => {
  // iconType possible values: 'Ok', 'Warning', 'Error'
  const [showTooltipContent, setShowTooltipContent] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(content);
  const [tooltipIcon, setTooltipIcon] = useState(icon);

  useEffect(() => {
    setTooltipContent(content);
  }, [content]);
  useEffect(() => {
    setTooltipIcon(icon);
  }, [icon]);

  // console.log(content);
  // tooltipIcon and tooltipContent are stored in state so that they are not executed
  // every re-render, but rather only when they change.

  return (
    <div
      className={`${styles.Icon} ${styles[tooltipIcon]}`}
      onMouseEnter={() => {
        if (content.length > 0) {
          setShowTooltipContent(true);
        }
      }}
      onMouseLeave={() => setShowTooltipContent(false)}
    >
      {content && showTooltipContent && (
        <div className={styles.Tooltip}>
        <div dangerouslySetInnerHTML={{ __html: tooltipContent }}></div>
          <div className={styles.TooltipCorner} />
        </div>
      )}
    </div>
  );
};
