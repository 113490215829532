import React from 'react';

// imports for syncfusion rich text editor
import './RichTextEditor.css';
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';

const RichTextEditor = ({ label, name, value, id, handleChange }) => {
  return (
    <>
      <label htmlFor={name}>{label}</label>
      <RichTextEditorComponent
        height={250}
        htmlAttributes={{ name: { name } }}
        value={value}
        change={(e) => handleChange(id, e.value, name)}
        id={name}
        data-testid={name}
      >
        <Inject services={[HtmlEditor, Toolbar, Image, Link, QuickToolbar]} />
      </RichTextEditorComponent>
    </>
  );
};

export default RichTextEditor;
