import { updateObject, getProjectCountries } from '../../utility/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  breadcrumbs: [{ text: 'Waves', link: '/' }],
  userSelectedProjects : [],
  user: {
    isAuthenticated: false,
    user: null,
    msalUser: null,
    userName: null,
    countries: [],
  },
  isLoading: false,
};

const updateCrumbs = (state, action) => {
  return {
    ...state,
    breadcrumbs: [...action.crumbs],
  };
};

const updateUserSelectedProjects = (state, action) => {
  return {
    ...state,
    userSelectedProjects: [...action.userSelectedProjects],
  };
};

const projectIdsStart = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const projectIdsFail = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};
const getProjectIdsSuccess = (state, action) => {
  const updatedWaves = updateObject(state, {
    projects: action.projects,
    projectCountries: getProjectCountries(action.projects)
  });
  return updatedWaves;
};

const authStart = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const authFail = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};
const authSuccess = (state, action) => {
  return {
    ...state,
    user: { ...action.userData },
    isLoading: false,
  };
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CRUMBS:
      return updateCrumbs(state, action);
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state);
    case actionTypes.GET_PROJECT_IDS_START:
      return projectIdsStart(state);
    case actionTypes.GET_PROJECT_IDS_SUCCESS:
      return getProjectIdsSuccess(state, action);
    case actionTypes.GET_PROJECT_IDS_FAIL:
      return projectIdsFail(state);
    case actionTypes.UPDATE_USERPROJECTS_SELECTIONFILTER:
      return updateUserSelectedProjects(state, action);
    default:
      return state;
  }
};

export default appReducer;
