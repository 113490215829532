import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SidePanel from '../../containers/sidePanel/SidePanel';
import ModuleContent from '../../containers/moduleContent/ModuleContent';
import styles from './ModuleSelection.module.css';
import QuestionForm from '../../containers/questionForm/QuestionForm';
import QuestionSubmit from '../../ui/QuestionSubmit/QuestionSubmit';
import ApproveCustomQuestion from '../../containers/approveCustomQuestion/ApproveCustomQuestion';
import QuestionPreview from '../../containers/questionPreview/QuestionPreview';
import Button from '../../ui/buttons/Button';
import {
  updateCrumbs,
  getNotifications,
  sendNotification,
} from '../../../store/actions/index';
import {
  updateTitle,
  checkUXPrivileges,
  getWaveNameWithStartDate,
} from '../../../utility/utility';
import { UpdateChangesToApprove, getProjectIds } from '../../../store/actions';
import { getRelevantPage } from './ModuleSelection.utility';
import ModalDialog from '../../ui/modal/Modal';
import ConfirmNoCustomQ from './ConfirmNoCustomQ';
import RejectModuleSelection from '../../ui/RejectModuleSelection/RejectModuleSelection';

const ModuleSelection = ({ name }) => {
  const { country, waveId } = useParams();
  const dispatch = useDispatch();

  const customQuestions = useSelector(
    (state) => state.customQuestions.customQuestions
  );
  const wave = useSelector((state) => state.wave.wave);
  const { uxPrivileges } = useSelector((state) => state.app.user.user.role);
  const { notifications } = useSelector((store) => store.notifications);
  const { changesToApprove, changesToSave } = useSelector(
    (state) => state.modules
  );
  const projectCountries = useSelector((state) => state.app.projectCountries);

  const [maxLOI, SetMaxLoi] = useState(0);

  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showRejectSelectionModal, setShowRejectSelectionModal] =
    useState(false);
  const [disableRejectButton, setDisableRejectButton] = useState(false);

    const [moduleSelectionStatus, setModuleSelectionStatus] = useState('');
    const [IsModuleSelectionPageActive, setIsModuleSelectionPageActive] = useState(false);

  const showQuestionDetails = useSelector(
    (state) => state.modules?.showQuestionDetails || false
  );

  useEffect(() => {
    dispatch(
      updateCrumbs([
        { text: 'Waves', link: '/' },
        { text: country, link: `waves/${country}` },
        { text: getWaveNameWithStartDate(wave), link: null },
      ])
    );
    updateTitle(` | ${wave.name}`);

    if (!wave.waveProcesses || moduleSelectionStatus !== null) return;
    wave.waveProcesses.find((process) => {
      if (process?.process?.name === 'Module selection') {
        return setModuleSelectionStatus(process.status);
      }
    });
  }, [wave]);

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const [countryId, setCountryId] = useState(null);

  useEffect(() => {
    setDisableConfirmButton(!changesToApprove);
  }, [changesToApprove]);
  useEffect(() => {
    if (projectCountries) {
      const projectCountry = projectCountries.find(
        (pc) =>
          pc.countryId === wave.countryId && pc.projectId === wave.projectId
      );
      if (projectCountry) {
        setCountryId(projectCountry.countryId);
      }
      if (projectCountry?.maxLOI) {
        SetMaxLoi(projectCountry.maxLOI);
      }
    } else {
      dispatch(getProjectIds());
    }
  }, [dispatch, projectCountries, wave.countryId, wave.projectId]);

  const [content, setContent] = useState(null);
  //apparently this optimizes the code and fixes a sonarqube smell. Note that the dependency is important (can break it otherwise)
  const questionclickHandler = useCallback(
      (clikcItemType, clickItemId) =>
          setContent(
              getRelevantPage(
                  clikcItemType,
                  clickItemId,
                  name,
                  uxPrivileges,
                  customQuestions,
                  questionclickHandler,
                  moduleSelectionStatus // If the status of the wave's Module Selection is set to 'Finished' the
                  // custom question will be displayed in preview mode.
              )
          ),
    [customQuestions]
    );
    const selectedPageTypeHandler = (isModuleSelectionPageActive) => {
        setIsModuleSelectionPageActive(isModuleSelectionPageActive);
    };

  const handleNotifications = () => {
    /* notify approve selection */
    dispatch(
      sendNotification(
        notifications.approveSelection,
        wave.countryId,
        wave.waveId
      )
    );
    dispatch(UpdateChangesToApprove(false));
  };

  const handleRejectModuleSelection = (userInput) => {
    const inputParams = {
      TextboxMessage: userInput,
      WaveLink: getWaveLink(),
    };

    dispatch(
      sendNotification(
        notifications.rejectSelection,
        wave.countryId,
        wave.waveId,
        inputParams
      )
    );
    setDisableRejectButton(true);
    dispatch(UpdateChangesToApprove(false));
  };

  const getWaveLink = () => {
    return window.location.href;
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShowRejectSelectionModal = () => {
    setShowRejectSelectionModal(true);
  };

  const handleRejectSelectionModalClose = () => {
    setShowRejectSelectionModal(false);
  };
  const renderSendNotificationUI = () => {
    return (
      <>
        <div className={styles.confirmContainer}>
          {checkUXPrivileges(uxPrivileges, 'submitModuleSelection') && (
            <ModalDialog
              show={showModal}
              content={
                <ConfirmNoCustomQ
                  id="id-submitModuleSelectionDialog"
                  handleApprove={handleNotifications}
                  handleClose={handleClose}
                  noLocalQ={Object.keys(customQuestions).length === 0}
                  changesToSave={changesToSave}
                />
              }
            />
          )}
          {checkUXPrivileges(uxPrivileges, 'approveModule') && (
            <ModalDialog
              show={showRejectSelectionModal}
              content={
                <RejectModuleSelection
                  id="id-rejectModuleSelectionDialog"
                  handleReject={handleRejectModuleSelection}
                  handleClose={handleRejectSelectionModalClose}
                />
              }
            />
          )}
          {(checkUXPrivileges(uxPrivileges, 'submitModuleSelection') ||
            checkUXPrivileges(uxPrivileges, 'approveModule')) && (
            <div className={styles.buttonMargin}>
                        {checkUXPrivileges(uxPrivileges, 'submitModuleSelection') && (
                <Button
                  type="primary"
                  handleOnClick={handleShowModal}
                  disabled={disableConfirmButton}
                >
                  Submit final selection
                            </Button>
                        )}
              { IsModuleSelectionPageActive && checkUXPrivileges(uxPrivileges, 'approveModule') && (
                <Button
                  type="primary"
                  disabled={disableRejectButton}
                  handleOnClick={handleShowRejectSelectionModal}
                >
                  Reject module selection
                </Button>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className={styles.Page}>
      <SidePanel
        waveId={waveId}
        projectId={wave.projectId}
        maxCustomQ={3}
        maxLoi={maxLOI}
        clickHandler={questionclickHandler}
        selectedPageTypeHandler={selectedPageTypeHandler}
        isReadOnly={moduleSelectionStatus.current === 'Finished'}
        moduleSelectionStatus={moduleSelectionStatus.current}
        countryId={countryId}
      ></SidePanel>
      {content}
      {renderSendNotificationUI()}
    </div>
  );
};

export default ModuleSelection;
