export const sortCountryWaveProcesses = (countries) => {
  countries.map((country) => {
    country.waves.map((countryWave) => {
      countryWave.waveProcesses.sort((first, second) =>
        first.process.sequence > second.process.sequence ? 1 : -1
        );        
      let fieldworkObj = countryWave.waveProcesses.find(
        (waveProcess) => waveProcess.process?.name === 'Fieldwork'
      );

      fieldworkObj
        ? (countryWave.fieldworkStartDate = fieldworkObj.startDate.slice(0, 10))
        : (countryWave.fieldworkStartDate = null); // default value set to null required for sorting
    });
    country.waves.sort((countryWaveFirst, countryWaveSecond) =>
      new Date(countryWaveFirst.fieldworkStartDate).getTime() >
      new Date(countryWaveSecond.fieldworkStartDate).getTime()
        ? 1
        : -1
    ); // sort waves by fieldworkStartDate
    country.waves.sort((countryWaveFirst, countryWaveSecond) =>
      countryWaveFirst.fieldworkStartDate > countryWaveSecond.fieldworkStartDate
        ? 1
        : -1
    ); // place waves that do not have a fieldworkStartDate at the end of the array
  });
  return countries;
};
