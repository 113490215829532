
import { useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import styles from './SearchableDropdown.module.css'
import PropTypes from 'prop-types'

function SearchableDropdown({waveList, handleInputProp, id}) {

  const itemList = useRef([])
  const [showList,setShowList] = useState(false)
  const [validated, setValidated] = useState(true)
  const [hovered, setHovered] = useState(-1)
  const [filterText, setFilterText] = useState("")
  const deferredFilterText = useDeferredValue(filterText)
  const collection = useRef()

  useEffect(() => {
    itemList.current = waveList.map(wave=>{
      return wave.name
    })
  },[])

  
  //rerender delay with deferredValue hook
  const deferredList = useMemo(() => {
    return waveList.filter((item, index) => (item.name.toLowerCase().indexOf(deferredFilterText.toLowerCase()) != -1 || deferredFilterText.length === 0))
  }, [deferredFilterText])

  
  collection.current = deferredList.map((el, index) => {
    return(
      <div key={index} className={`${styles.ListItem} ${hovered===index?styles.Hovered:''}`} onClick={(e) => handleSelect(el.name, e)} onMouseEnter={()=>hoverStart(index)} onMouseLeave={()=>hoverEnd()} >
        <span>{el.name}</span>
      </div>
    )
  })

  const handleInput = (e) => {
    let val = e.target.value
    
    if(validated){            
      setValidated(true)
      setShowList(true)
      setHovered(-1)           
    }

    setFilterText(val) 
  }

  const handleSelect = (val, e, showlist) => {
    console.log(val, 'ssssssssssssssssssssss')
    e.stopPropagation()
    handleInputProp(val, id)
    let sh = false;
    if(showlist){
        sh = true
    }
    
    setShowList(sh)
    setValidated(true)
    setFilterText(val)
  }

  const toggleList = () => {
    let currentListState = showList
    let filterTxt = filterText
    if(currentListState){
      let lowerCaseList = waveList.map(item => item.name.toLowerCase());
      if(lowerCaseList.indexOf(filterTxt.toLowerCase()) === -1){
          handleInputProp('', id)
      }
      else{
          handleInputProp(filterTxt, id)
      }
    }
    
    setShowList(!currentListState)    
    setFilterText(filterTxt)        
  }

  const hoverStart = (ind) => {
    setHovered(ind)
  }

  const hoverEnd = () => {
      setHovered(-1)
  }
  
  const keyPress = (e) => {
    if(e.keyCode === 40){
        let hoverInd = hovered
        if (hoverInd < deferredList.length -1){
            hoverInd++;
            setShowList(true)
            setHovered(hoverInd)
        }
    }
    if(e.keyCode === 38){
        let hoverInd = hovered
        if (hoverInd > 0){
            hoverInd--;
            setHovered(hoverInd)
        }
    }
    if(e.keyCode === 13){
        let hoverInd = hovered
        if (hoverInd !== -1){
            let selected = deferredList[hoverInd]
            handleSelect(selected, e)
        }
    }
  }

  return(
    <div data-testid='id-searchableDropdown' className={`${styles.DropDownContainer} ${showList?styles.Expanded:''}`} onClick={()=>toggleList()} onKeyDown={(e)=>keyPress(e)}>
			<input data-testid='id-input' type="text" className={styles.DropDownSearch} value ={filterText} onChange={handleInput} ></input>
			<div className={`${styles.ListContainer} ${showList?'':styles.Hidden}`}>
        {collection.current}
			</div>
		 </div>
  )
}

SearchableDropdown.propTypes = {
  waveList: PropTypes.array.isRequired
}

export default SearchableDropdown