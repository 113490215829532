import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './AccordionMenu.module.css';

import iconUp from '../../../assets/svg/accordion_up.svg';
import iconDown from '../../../assets/svg/accordion_down.svg';

const AccordionMenuItem = ({
  id,
  name,
  expanded,
  onClickSubitem,
  onClickItem,
  idCurrentSubitem,
  setCurrent,
  subitems,
  currentCountryId,
  scrollTo,
}) => {
  const [showSubitems, setShowSubitems] = useState(expanded);

  const [ref, setRef] = useState(null);
  const refCurrentCountry = useRef(null);

  useEffect(() => {
    setRef(refCurrentCountry);
  }, [currentCountryId]);

  useEffect(() => {
    if (scrollTo !== undefined && scrollTo !== -1 && !isNaN(scrollTo)) {
      // prevents automatic scrolling to the bottom
      // if scrollTo is does not have an acceptable value
      refCurrentCountry.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [ref]);

  return (
    <div
      className={styles.item}
      data-testid={id}
      aria-label={name}
      aria-expanded={showSubitems}
      ref={ref}
    >
      {/* show the header always */}
      <div className={`${styles.header}`}>
        <button
          className={`${styles.btn}`}
          tabIndex={1}
          onClick={() => {
            onClickItem(id);
            return id;
          }}
        >
          <span>{name}</span>
        </button>
      </div>
    </div>
  );
};

AccordionMenuItem.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClickSubitem: PropTypes.func.isRequired,
  idCurrentSubitem: PropTypes.any.isRequired,
  setCurrent: PropTypes.func.isRequired,
  subitems: PropTypes.array.isRequired,
};

AccordionMenuItem.defaultProps = {
  id: -1,
  name: 'default',
  expanded: false,
  onClickSubitem: (id) => {
    return id;
  },
  idCurrentSubitem: -1,
  setCurrent: (id) => {
    console.log(id);
  },
  subitems: [
    {
      id: -1,
      name: 'default',
    },
  ],
};

export default AccordionMenuItem;
