import React, { useState } from "react";
import PropTypes from 'prop-types'
import classes from './TextInput.module.css';

const TextInput = ({ label, placeholder, required, disabled, autoFocus, id, value, invalid, error, onChange, children }) => {
    const [inputValue, setInputValue] = useState(value || "");

    const change = (id, val) => {
        setInputValue(val);
        onChange(id, val)
    }

    return (
        <>
            <label data-testid='input-label' className={classes.label}>{children}</label><br />
            <input
                data-testid='text-input'
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                autoFocus={autoFocus}
                onChange={(event) => change(id, event.target.value)}
                value={inputValue}
                id={id}
                name={id}
                aria-label={label}
                aria-invalid={invalid}
                aria-errormessage="text-input-error"
                className={classes.textInput}
            /><br />
            {invalid ? (
                <p className={classes.textInputError} id='text-input-error'>{error}</p>) : null}<br />
        </>
    )
}

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func,
}

TextInput.defaultProps = {
    label: 'textInputLabel',
    placeholder: '',
    required: false,
    disabled: false,
    invalid: false,
    onChange: (id, value) => {
        console.log(id, value);
    },
    error: ''
}

export default TextInput;