import React, { forwardRef, useEffect, useState } from 'react';
import styles from './ProcessComponent.module.css';
import DatePicker from '../datePicker/DatePicker';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import Button from '../buttons/Button';

const ProcessComponent = forwardRef(
  (
    {
      type,
      startDate,
      endDate,
      status,
      onChange,
      isNewWave,
      onDelete,
      onRestore,
      isValidFail,
    },
    ref
  ) => {
    const [procStatus, setProcStatus] = useState(
      status ? status : 'placeholder'
    );
    const [sDate, setSDate] = useState(startDate ? startDate : '');
    const [eDate, setEDate] = useState(endDate ? endDate : '');
    const [isClosed, setIsClosed] = useState(isNewWave ? false : true);
    const [validFail, setValidFail] = useState(false);

    useEffect(() => {
      setValidFail(isValidFail);
    }, [isValidFail]);

    useEffect(() => {
      if (startDate && endDate && status && isClosed) {
        setIsClosed(false);
      }
    }, [status, startDate, endDate]);

    useEffect(() => {
      setProcStatus(status);
    }, [status]);

    useEffect(() => {
      setSDate(startDate);
    }, [startDate]);

    useEffect(() => {
      setEDate(endDate);
    }, [endDate]);

    const handleStatusChange = (event) => {
      const val = event.target.value;
      setProcStatus(val);
      onChange(type, 'status', val);
    };

    const getDate = (date, id) => {
      date.setHours(18); //setting time to EOD, so different timezones should be unaffected of the server converting the date
      const isoDate = date.toISOString();
      onChange(type, id, isoDate);
    };

    const handleProcessDelete = () => {
      setIsClosed(true);
      onDelete(type);
      setValidFail(false);
    };

    const handleProcessRestore = () => {
      setIsClosed(false);
      onRestore(type);
      setValidFail(isValidFail);
    };

    return (
      <div
        className={`${styles.Wrapper} ${isClosed ? styles.NoProcess : ''} ${
          validFail ? styles.Error : ''
        }`}
        ref={ref}
      >
        {isClosed ? (
          <Button
            type={'selectionMini'}
            handleOnClick={() => handleProcessRestore()}
          >
            Restore
          </Button>
        ) : (
          <Button type={'close'} handleOnClick={() => handleProcessDelete()} />
        )}
        <div className={`${styles.Type} ${isClosed && styles.DisabledProcess}`}>
          {type} {isClosed ? ' - Removed' : ''}
        </div>
        {!isClosed ? (
          <div className={styles.SelectionMenu}>
            <div className={styles.InputItem}>
              <label>Start date</label>
              <DatePicker id={'startDate'} getDate={getDate} date={sDate} />
            </div>
            <div className={styles.InputItem}>
              <label>End date</label>
              <DatePicker id={'endDate'} getDate={getDate} date={eDate} />
            </div>
            <div className={styles.InputItem}>
              <Dropdown
                id={'dd1'}
                label={'Status'}
                value={procStatus}
                placeholder={' '}
                options={[
                  { label: 'Pending', value: 'Pending' },
                  { label: 'In progress', value: 'In progress' },
                  { label: 'Finished', value: 'Finished' },
                ]}
                onChange={handleStatusChange}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

ProcessComponent.propTypes = {
  type: PropTypes.oneOf([
    'Module selection',
    'Scripting',
    'Fieldwork',
    'Data processing',
    'Reporting',
  ]).isRequired,
};

export default ProcessComponent;
