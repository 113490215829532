import { checkMandatoryChoiceGroup } from './utility'
import { getMessageWithReplacedKeyValuePairs, getMessageWithReplacedKeyValue } from './utility';
import { getStore } from './injectStore';

const getMessages = () => {
    return getStore().message?.messages
};
export const LOImessage = (maxLoi) => {
  const messages = getMessages();
  return getMessageWithReplacedKeyValue(messages?.maxLOI?.text, 'maxLoi', maxLoi);
};

export const setModuleMandatoryMessage = (messageType, moduleNames) => {
  const messages = getMessages();
  const isBPIModules = moduleNames.find((string) =>
    string.includes('Brand & Product Image RMC')
  );

  //list of modules for the toast notification
  let lista = moduleNames.join('</li><li>');
  let list = '<ul><li>' + lista + '</li></ul>';

  let messageIntro = '';
  if (messageType === 'hover') {
    //Specific message for Brand & Product Image RMC
    if (isBPIModules) {
      messageIntro = messages?.brandProductImageRMC?.text;
        list = '';
    } else {
      //leaving an option for another mandatory 1 of many group message
      messageIntro = messages?.mandatoryChoiceHover?.text;
        }
  } else if (messageType === 'toast') {
    messageIntro = messages?.mandatoryChoiceToast?.text;
  }
  return `${messageIntro} ${list}`;
};

export const stackMessages = (messagesObj) => {
  let messages = '';
  Object.values(messagesObj).forEach((error, index) => {
    messages += `${index + 1}. ${error} <br />`;
  });
  return messages;
};

export const setCreateWaveErrorMessage = (type) => {
  const messages = getMessages();
  let msgObj = {
    title: '',
    message: '',
  };
  if (type === 'missingProcess') {
      msgObj.title = messages?.createWaveErrorMessageOnMissingProcess?.title;
      msgObj.message = messages?.createWaveErrorMessageOnMissingProcess?.text;
  } else if (type === 'missingValue') {
      msgObj.title = messages?.createWaveErrorMessageOnMissingValue?.title;
      msgObj.message = messages?.createWaveErrorMessageOnMissingValue?.text;
  } else if (type === 'missingName') {
      msgObj.title = messages?.createWaveErrorMessageOnMissingName?.title;
      msgObj.message = messages?.createWaveErrorMessageOnMissingName?.text;
  }

  return msgObj;
};

export const handleMandatoryMessage = (
  groupData,
  mandatoryChoiceGroup,
  savedModuleSelection,
  moduleNames
) => {
  const messages = getMessages();
  if (groupData.mandatoryOncePerYear && groupData.mandatoryThisWave) {
    return messages?.moduleMandatoryOncePerYearLastWave?.text;
  } else if (groupData.mandatoryOncePerYear) {
    return messages?.moduleMandatoryOncePerYear?.text;
  } else if (
    groupData.mandatoryGroupRuleOncePerYear &&
    groupData.mandatoryGroupThisWave
  ) {
    return messages?.groupMandatoryOncePerYearLastWave?.text;
  } else if (groupData.mandatoryGroupRuleOncePerYear) {
    return messages?.groupMandatoryOncePerYear?.text;    
  } else if (checkMandatoryChoiceGroup(mandatoryChoiceGroup)) {
    let modules = '<ul>';
    moduleNames.forEach(mod => {modules += `<li>${mod}</li>`})
    modules += '</ul>'
    if (!mandatoryChoiceGroup.valid && !savedModuleSelection) {
      return getMessageWithReplacedKeyValue(messages?.mandatoryChoiceGroupValidNotSavedModuleSelection?.text, 'modules',modules);
    } else if (!mandatoryChoiceGroup.valid && savedModuleSelection) {
      return getMessageWithReplacedKeyValue(messages?.mandatoryChoiceGroupNotValidSavedModuleSelection?.text, 'modules',modules);
    }
    // if (!savedModuleSelection && !mandatoryChoiceGroup.valid) {
    //   return 'At leas one of the following xxx modules needs to be selected.';
    // } else if (!mandatoryChoiceGroup.valid) {
    //   return 'Please select at least one module from xxx modules.';
    // }
  }
  return true;
};

//using the message for cascadingSelection, if needed can be reworked to use separate messages coming from DB
export const dependencyChoiceMessages = (action, modules, trigger) => {
  const messages = getMessages()
  let messageList = modules
  let msgObj = {
    title: 'Automatic module selection',
    message: '',
  };
  const replacementKeyValues = [
    { key: "trigger", value: trigger },
    { key: "messageList", value: messageList },
  ];
  if(action === 'deselect'){
    msgObj.message = getMessageWithReplacedKeyValuePairs(messages?.cascadingSelectionMessageOnDeselect?.text, 
    replacementKeyValues);
  }
  return msgObj
}

export const cascadingSelectionMessages = (action, modules, trigger) => {
  const messages = getMessages();
  let messageList = stackMessages(modules)
  let msgObj = {
    title: 'Automatic module selection',
    message: '',
  };
  const replacementKeyValues = [
    { key: "trigger", value: trigger },
    { key: "messageList", value: messageList },
  ];

  if(action === 'select'){
    msgObj.message = getMessageWithReplacedKeyValuePairs(messages?.cascadingSelectionMessageOnSelect?.text, 
      replacementKeyValues);
  }
  else if(action === 'deselect'){
      msgObj.message = getMessageWithReplacedKeyValuePairs(messages?.cascadingSelectionMessageOnDeselect?.text, 
      replacementKeyValues);
  }
  else if(action === 'undo'){
      msgObj.message = getMessageWithReplacedKeyValuePairs(messages?.cascadingSelectionMessageOnUndo?.text, 
        replacementKeyValues);
  }
  return msgObj;
}
