import React from 'react';
import PropTypes from 'prop-types';

import styles from './AccordionDisplay.module.css';
import AccordionDisplayItem from './AccordionDisplayItem';

const AccordionDisplay = ({
  filteredProjectList,
  country,
  countryName,
  items,
  currentWaveId,
  onClickModuleSelection,
  onClickEditWave,
  editWavesPrivilege,
  statusToStatusType,
  onClickModulePreview,
}) => {
  const handleModuleSelectionClick = (id) => {
    onClickModuleSelection(country, id);
  };
  const handleModulePreviewClick = (id) => {
    onClickModulePreview(country, id);
  };
  const handleEditWave = (id) => {
    onClickEditWave(country, id);
  };

  return (
    <div className={styles.container}>
      {items.filter(item => filteredProjectList.includes(item.projectId)).map((item) => {
        return (
          <AccordionDisplayItem
            key={item.waveId}
            {...item}
            currentWaveId={currentWaveId}
            onClickModuleSelection={handleModuleSelectionClick}
            onClickEditWave={handleEditWave}
            statusToStatusType={statusToStatusType}
            editWavesPrivilege={editWavesPrivilege}
            onClickModulePreview={handleModulePreviewClick}
          />
        );
      })}
    </div>
  );
};

AccordionDisplay.propTypes = {
  items: PropTypes.array.isRequired,
  onClickModuleSelection: PropTypes.func.isRequired,
  onClickModulePreview: PropTypes.func.isRequired,
};

AccordionDisplay.defaultProps = {
  items: [
    {
      waveId: -1,
      name: 'default',
      project: { name: 'default' },
      waveStatus: { status: 'default' },
    },
  ],
  currentWaveId: -1,
  onClickModuleSelection: (waveId) => {
    return waveId;
  },
  onClickModulePreview: (waveId) => {
    return waveId;
  },
};

export default AccordionDisplay;
