import React, { useState, useEffect, useContext, createContext, useMemo } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { getUserData } from "../store/actions";
export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);
const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [{isAuthenticated, user, msalUser, userName}, isLoading] = useSelector((state) => [state.app.user, state.app.isLoading]); 
  
  useEffect(() => {
    dispatch(getUserData());
  }, [])

  const login = () => {
    window.location.href = "/api/auth/login";
  };

  const logout = () => {
    window.location.href = "/api/auth/logout";
  };

  
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        msalUser,
        userName,
        isLoading,
        login,
        logout}
      }
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
