import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './QuestionPreview.module.css';
import Tabs from '../../ui/nav/Tabs';
import Checkbox from '../../ui/checkbox/Checkbox';
import { setLanguageSettings } from '../../pages/moduleSelection/ModuleSelection.utility';
import { getStatus, GetFormattedLocalQuestionName } from '../../../utility/utility';
import DPInstructionTablePreview from '../dpInstructionTablePreview/DPInstructionTablePreview';

/**
 * @class QuestionPreview
 * @param {Number} customQuestionId
 *
 * @description This component is used to render a preview of a local question and its translations for each language available for this wave ( if any ). It also shows the translation and legal approved status of the question.
 *
 * The **QuestionPreview** component is used in the **QuestionnairePreview** component.
 *
 * @returns {JSX} Preview of a local question and its translations for each language available for the wave.
 */
const QuestionPreview = ({ customQuestionId }) => {
  const { isLoading, customQuestions, error } = useSelector(
    (store) => store.customQuestions
  );
  const { wave } = useSelector((store) => store.wave);
  const [languages, setLanguages] = useState([]);
  const [currentLanguageId, setCurrentLanguageId] = useState(-1);
    const [texts, setTexts] = useState({});
    const [dpInstructionFormsData, setDPInstructionFormsData] = useState([]);

  /**
   * @function
   * @memberof QuestionPreview
   * @param {Number} languageId Id of the language that is clicked on.
   * Calls 'setCurrentLanguageId' with the value of the 'languageId' argument, and stores 'languageId' in state.
   */
  const handleLanguage = (languageId) => {
    setCurrentLanguageId(languageId);
  };

  /**
   * @method useEffect
   * @memberof QuestionPreview
   * @param {Object} wave All data pertaining to the current wave.
   * @param {Array} customQuestions Array containing all local questions for this wave.
   * @param {Number} customQuestionId Id of the local question whose contents we are currently rendering.
   *
   * @description  When either 'customQuestionId', 'wave' or 'customQuestions' changes, it renders the contents of the appropriate local question.
   * @see {@link '../../pages/moduleSelection/ModuleSelection.utility'}, lines 142-161
   * As the dependencies do not change after the initial load, this effect is only executed after the component is initially mounted, once all required data is available in the store.
   */
  useEffect(() => {
    if (
      Object.keys(customQuestions).length > 0 &&
      Object.keys(customQuestions).includes(customQuestionId.toString())
    ) {
      if (languages.length === 0) {
        if (wave.waveLanguages) {
          setLanguageSettings(wave, setLanguages, setCurrentLanguageId);
        }
      }
    }
  }, [wave, customQuestions, customQuestionId]);

  /**
   * @method useEffect
   * @memberof QuestionPreview

   * @description  Updates the 'texts' state variable with the localQuestion object containing all data pertaining to the local question being previewed. If a current language id is available it checks customQuestions[customQuestionId].question.questionTextField, finds the version of the question corresponding to that question and calls 'setTexts' with the resulting object. If a current language id isn't available it calls 'setTexts' with the first language version of the question available in the 'questionTextField' array.
   * This effect is executed on every render of the component.
   */
  useEffect(() => {
    if (currentLanguageId !== -1 && customQuestions) {
      setTexts(
        customQuestions[customQuestionId].question.questionTextFields.find(
          (q) => {
            return q.languageId === currentLanguageId;
          }
        )
      );
    } else {
      setTexts(
        customQuestions[customQuestionId].question.questionTextFields[0]
      );
    }
  });

    console.log(customQuestions);

  return (
    <div data-testid="id-QuestionPreview">
      {isLoading && <div>Loading...</div>}
      {error && <div className={styles.error}>{error}</div>}
      {customQuestions.hasOwnProperty(customQuestionId) && (
        <div className={styles.form}>
          <h3>            
              {GetFormattedLocalQuestionName(customQuestions[customQuestionId].customNamePrefix, customQuestions[customQuestionId].question.name)}
          </h3>{' '}
          <div className={styles.formControl} id="questionStatus">
                      <label aria-labelledby="questionStatus">
                          Question status: {getStatus(customQuestions[customQuestionId].customQuestionStatus.status)}
            </label>
          </div>
          <div className={styles.tabContainer}>
            <Tabs
              labelsTabs={languages}
              idCurrent={currentLanguageId}
              handleOnClick={handleLanguage}
            ></Tabs>
          </div>
          <div className={styles.tabContent}>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Variable code</label>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    customQuestions[customQuestionId].question?.variableCode,
                }}
                id="variableCode"
              />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Question type</label>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    customQuestions[customQuestionId].question.questionType &&
                    customQuestions[customQuestionId].question.questionType
                      .name,
                }}
                id="questionType"
              />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Base</label>
              <p
                dangerouslySetInnerHTML={{
                  __html: customQuestions[customQuestionId].question.base,
                }}
                id="base"
              />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Question text</label>
              <p
                dangerouslySetInnerHTML={{ __html: texts.questionText }}
                id="questionText"
              />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Answers</label>
              <p
                dangerouslySetInnerHTML={{ __html: texts.answerText }}
                id="answerText"
              />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>Scripting instructions</label>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    customQuestions[customQuestionId].scriptingInstruction,
                }}
                id="scriptingInstructions"
              />
            </div>
            <div
              className={`${styles.formControl} ${styles.questionTextControl}`}
            >
              <label>DP Table Instructions</label>
                      </div>

                      {
                          customQuestions[customQuestionId].dpInstructions?.map((item, index) => {
                              return (<DPInstructionTablePreview id={item.dpInstructionsId} index={index} item={item} />)
                          })
                      }

            <div className={styles.CheckboxContainer}>
              <Checkbox
                id="translationApproval"
                label={'Translation has been approved'}
                disabled={true}
                checked={texts.isTranslationApproved}
                testId="isTranslationApproved"
              ></Checkbox>
            </div>
          </div>
          <div>
            <div className={styles.CheckboxContainer}>
              <Checkbox
                id="legalApproval"
                label={'Question and translations have been legally approved'}
                disabled={true}
                checked={customQuestions[customQuestionId].isLegalApproved}
                testId="isLegallyApproved"
              ></Checkbox>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionPreview;
