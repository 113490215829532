export const DEMO_ACTION = 'DEMO_ACTION';
export const DEMO_START = 'DEMO_START';
export const DEMO_SUCCESS = 'DEMO_SUCCESS';
export const DEMO_FAIL = 'DEMO_FAIL';

export const FETCH_MODULES_LIST_START = 'FETCH_MODULES_LIST_START';
export const FETCH_MODULES_LIST_SUCCESS = 'FETCH_MODULES_LIST_SUCCESS';
export const FETCH_MODULES_LIST_FAIL = 'FETCH_MODULES_LIST_FAIL';

export const GET_ALL_PROJECT_MODULES_SUCCESS = 'GET_ALL_PROJECT_MODULES_SUCCESS';

export const UPDATE_WAVE_STATUS = 'UPDATE_WAVE_STATUS';
export const UPDATE_MODULE_SELECTION = 'UPDATE_MODULE_SELECTION';
export const UPDATE_MULTIPLE_MODULE_SELECTION = 'UPDATE_MULTIPLE_MODULE_SELECTION';
export const UPDATE_ACTIVE_MODULE = 'UPDATE_ACTIVE_MODULE';

export const BATCH_UPDATE_MODULES = 'BATCH_UPDATE_MODULES';

export const SAVE_MODULE_SELECTION = 'SAVE_MODULE_SELECTION';
export const SAVE_MODULE_SELECTION_START = 'SAVE_MODULE_SELECTION_START';
export const SAVE_MODULE_SELECTION_SUCCESS = 'SAVE_MODULE_SELECTION_SUCCESS';
export const SAVE_MODULE_SELECTION_FAIL = 'SAVE_MODULE_SELECTION_FAIL';

export const GET_CUSTOM_QUESTIONS_START = 'GET_CUSTOM_QUESTIONS_START';
export const GET_CUSTOM_QUESTIONS_SUCCESS = 'GET_CUSTOM_QUESTIONS_SUCCESS';
export const GET_CUSTOM_QUESTIONS_FAIL = 'GET_CUSTOM_QUESTIONS_FAIL';

export const GET_CUSTOM_QUESTION_TYPES_FAIL = 'GET_CUSTOM_QUESTION_TYPES_FAIL';
export const GET_CUSTOM_QUESTION_TYPES_SUCCESS =
  'GET_CUSTOM_QUESTION_TYPES_SUCCESS';

export const UPDATE_GROUP_VALIDATION = 'UPDATE_GROUP_VALIDATION';

export const CREATE_CUSTOM_QUESTION_START = 'CREATE_CUSTOM_QUESTION_START';
export const CREATE_CUSTOM_QUESTION_SUCCESS = 'CREATE_CUSTOM_QUESTION_SUCCESS';
export const CREATE_CUSTOM_QUESTION_FAIL = 'CREATE_CUSTOM_QUESTION_FAIL';

export const UPDATE_CUSTOM_QUESTION = 'UPDATE_CUSTOM_QUESTION';
export const UPDATE_CUSTOM_QUESTION_START = 'UPDATE_CUSTOM_QUESTION_START';
export const UPDATE_CUSTOM_QUESTION_SUCCESS = 'UPDATE_CUSTOM_QUESTION_SUCCESS';
export const UPDATE_CUSTOM_QUESTION_FAIL = 'UPDATE_CUSTOM_QUESTION_FAIL';

export const DELETE_CUSTOM_QUESTION_START = 'DELETE_CUSTOM_QUESTION_START';
export const DELETE_CUSTOM_QUESTION_SUCCESS = 'DELETE_CUSTOM_QUESTION_SUCCESS';
export const DELETE_CUSTOM_QUESTION_FAIL = 'DELETE_CUSTOM_QUESTION_FAIL';

export const UPDATE_CHANGES_TO_APPROVE = 'UPDATE_CHANGES_TO_APPROVE';

export const GET_WAVE_START = 'GET_WAVE_START';
export const GET_WAVE_SUCCESS = 'GET_WAVE_SUCCESS';
export const GET_WAVE_FAIL = 'GET_WAVE_FAIL';

export const GET_PROJECT_IDS_START = 'GET_PROJECT_IDS_START';
export const GET_PROJECT_IDS_SUCCESS = 'GET_PROJECT_IDS_SUCCESS';
export const GET_PROJECT_IDS_FAIL = 'GET_PROJECT_IDS_FAIL';

export const CLEAR_WAVE_DATA = 'CLEAR_WAVE_DATA';
export const CLEAR_MODULES_DATA = 'CLEAR_MODULES_DATA';

/*THESE MIGHT NOT BE USED */
export const GET_WAVE_PROCESS_START = 'GET_WAVE_PROCESS_START';
export const GET_WAVE_PROCESS_SUCCESS = 'GET_WAVE_PROCESS_SUCCESS';
export const GET_WAVE_PROCESS_FAIL = 'GET_WAVE_PROCESS_FAIL';
/*****/

export const GET_PROCESSES_START = 'GET_PROCESSES_START';
export const GET_PROCESSES_SUCCESS = 'GET_PROCESSES_SUCCESS';
export const GET_PROCESSES_FAIL = 'GET_PROCESSES_FAIL';

export const GET_WAVESTATUSES_START = 'GET_WAVESTATUSES_START';
export const GET_WAVESTATUSES_SUCCESS = 'GET_WAVESTATUSES_SUCCESS';
export const GET_WAVESTATUSES_FAIL = 'GET_WAVESTATUSES_FAIL';

export const DELETE_WAVE_PROCESS = 'DELETE_WAVE_PROCESS';

export const CREATE_WAVE_START = 'CREATE_WAVE_START';
export const CREATE_WAVE_SUCCESS = 'CREATE_WAVE_SUCCESS';
export const CREATE_WAVE_FAIL = 'CREATE_WAVE_FAIL';

export const UPDATE_WAVE_START = 'UPDATE_WAVE_START';
export const UPDATE_WAVE_SUCCESS = 'UPDATE_WAVE_SUCCESS';
export const UPDATE_WAVE_FAIL = 'UPDATE_WAVE_FAIL';

export const DELETE_WAVE_START = 'DELETE_WAVE_START';
export const DELETE_WAVE_SUCCESS = 'DELETE_WAVE_SUCCESS';
export const DELETE_WAVE_FAIL = 'DELETE_WAVE_FAIL';

export const COPY_WAVE_START = 'COPY_WAVE_START';
export const COPY_WAVE_SUCCESS = 'COPY_WAVE_SUCCESS';
export const COPY_WAVE_FAIL = 'COPY_WAVE_FAIL';

export const GET_COUNTRIES_WAVES_START = 'GET_COUNTRIES_WAVES_START';
export const GET_COUNTRIES_WAVES_SUCCESS = 'GET_COUNTRIES_WAVES_SUCCESS';
export const GET_COUNTRIES_WAVES_FAIL = 'GET_COUNTRIES_WAVES_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const UPDATE_CRUMBS = 'UPDATE_CRUMBS';

export const SEND_NOTIFICATION_START = 'SEND_NOTIFICATION_START';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAIL = 'SEND_NOTIFICATION_FAIL';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const UPDATE_PAGE_TOGGLE = 'UPDATE_PAGE_TOGGLE';
export const UPDATE_USERPROJECTS_SELECTIONFILTER =
  'UPDATE_USERPROJECTS_SELECTIONFILTER';


export const FETCH_MESSAGE_LIST_START = 'FETCH_MESSAGE_LIST_START';
export const FETCH_MESSAGE_LIST_SUCCESS = 'FETCH_MESSAGE_LIST_SUCCESS';
export const FETCH_MESSAGE_LIST_FAIL = 'FETCH_MESSAGE_LIST_FAIL';
