import * as React from 'react';
import { useState } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './DatePicker.css'

function DatePicker({getDate, id, date}) {
    const currentVal=date?date:''

    const handleChange = (event) => {
        const date = event.target.value
        getDate(date, id)
    }
    return (<div className='control-pane'>
                <div className='control-section'>
                    <div className='datepicker-control-section'>
                        <DatePickerComponent value={currentVal} format='dd/MM/yyyy' placeholder='Day / Month / Year' onChange={handleChange}></DatePickerComponent>
                    </div>
                </div>
            </div>);
}
export default DatePicker;

