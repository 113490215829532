import React from 'react';
import PropTypes from 'prop-types';
import styles from './Breadcrumbs.module.css';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }) => {
  return crumbs.length > 1 ? (
    <div className={styles.CrumbContainer}>
      {crumbs.map((crumb, ind, arr) => {
        let res = [];
        if (crumb.link) {
          res.push(
            <Link to={crumb.link} className={styles.Link} key={crumb.text}>
              {crumb.text}
            </Link>
          );
        } else {
          res.push(
            <span className={styles.Current} key={crumb.text}>
              {crumb.text}
            </span>
          );
        }
        if (ind !== arr.length - 1) {
          res.push(<span key={crumb.text + 'SLASH'}> / </span>);
        }
        return res;
      })}
    </div>
  ) : null;
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
};

export default Breadcrumbs;
