import styles from './SidePanelPreview.module.css';
import Logo from '../../../ui/logo/Logo';
import { useSelector } from 'react-redux';
import { GetFormattedLocalQuestionName, GetPreviewData } from '../../../../utility/utility';
import LengthOfInterview from '../../../ui/text/LengthOfInterview';
import { calcloi } from '../../../../utility/calcloi';

/**
 * @class
 * @param {Function} handleClick
 * @param {String } activeMenu String containing either`module_${index of module}' or `localQuestion${index of module}'.
 * @param {Function} setModuleId Function that returns a string `module_${indexOfModule}`.
 * @param {Function} setLocalQuestionId Function that returns a string `localQuestion_${indexOfLocalQuestion}`.
 * @param {Object} scrollOps Ref for the module/local question currently intersecting with the screen.
 *
 * @description Side panel containing all modules and local questions corresponding to this wave. This component's role is to allow the user to see which module/local question they are currently seeing ( as the one that is interseting with the screen will be highlighted ), as well as click on modules/local questions, and have the ModuleContent component automatically scroll them into view.
 *
 * @returns {JSX}
 */
const SidePanelPreview = ({
  handleClick,
  activeMenu,
  setModuleId,
  setLocalQuestionId,
  scrollOps,
  downloadAll,
}) => {
  const selectedModules = useSelector((state) =>
    GetPreviewData(state.modules.modules, 'sequence', 'selected')
  );
  const projectModules = useSelector((state) =>
    GetPreviewData(state.modules.projectModules, 'sequence')
  );

  const customQuestions = useSelector((state) =>
    GetPreviewData(state.customQuestions.customQuestions, 'sequence')
  );

  const modules = downloadAll ? projectModules : selectedModules;

  return (
    <div data-testid="id-SidePanelPreview" className={styles.sidebar}>
      <div data-testid="id-Gfklogo">
        <Logo></Logo>
      </div>
      <div className={styles.sidePanelItems}>
        <div className={`${styles.sidePanelItem} `}>
          <div role="label" className={`${styles.label} ${styles.labelHeader}`}>
            <b
              onClick={() =>
                scrollOps.current.modules.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                })
              }
            >
              Modules
            </b>
          </div>
        </div>
        {modules &&
          modules.map((module, index) => {
            const id = setModuleId(index);
            return (
              <div
                data-testid="id-module"
                className={`${styles.sidePanelItem} ${
                  activeMenu === id ? styles.active : null
                }`}
                onClick={() => handleClick(id)}
                id={id}
                key={id}
              >
                <div role="label" className={`${styles.label}`}>
                  {module.name}
                </div>
              </div>
          );
        })}
        {!downloadAll && <>
          <div className={`${styles.sidePanelItem} `}>
            {customQuestions.length > 0 && !downloadAll && (
              <div
                role="label"
                className={`${styles.label} ${styles.labelHeader}`}
              >
                <b
                  onClick={() =>
                    scrollOps.current.localQuestions.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                >
                  Local Questions
                </b>
              </div>
            )}
          </div>
          <ol style={{ padding: '0' }}>
            {customQuestions.map((customQuestion, index) => {
              const id = setLocalQuestionId(index);

              return (
                <div
                  data-testid="id-customQuestion"
                  className={`${styles.sidePanelItem} ${
                    styles.sidePanelCustomQuestion
                  } ${activeMenu === id ? styles.active : null}`}
                  onClick={() => handleClick(id)}
                  id={id}
                  key={id}
                >
                  <li
                    role="label"
                    className={`${styles.label} ${styles.labelCustomQuestion}`}
                      >
                          {GetFormattedLocalQuestionName(customQuestion?.customNamePrefix, customQuestion?.question?.name)}              
                  </li>
                </div>
              );
            })}
          </ol>
        </>}
      </div>
      {!downloadAll && <div className={styles.BottomContainer}>
        <LengthOfInterview number={calcloi(selectedModules, customQuestions)}>
          {['Length of interview: ', 'minutes']}
        </LengthOfInterview>
      </div>}
    </div>
  );
};
export default SidePanelPreview;
