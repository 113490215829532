import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./TextArea.module.css";

const TextArea = ({
  placeholder,
  required,
  disabled,
  id,
  value,
  invalid,
  error,
  onChange,
  children,
  additionalStyling
}) => {
  const [inputValue, setInputValue] = useState(value || "");

  const change = (id, val) => {
      setInputValue(val);
      onChange(id, val)
  }
  const getStyling = () => {
    let styleList = `${classes.textArea}`
    if (additionalStyling?.length > 0){
      additionalStyling.forEach(x => {
        styleList += ` ${classes[x]}`    
      });
    }
    return styleList
  }
  
  return (
    <>
      <label data-testid="input-label" className={classes.label}>
        {children}
      </label>
      <br />
      <textarea
        data-testid="text-area-input"
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(event) => change(id, event.target.value)}
        value={inputValue}
        id={id}
        name={id}
        aria-label={children}
        aria-invalid={invalid}
        aria-multiline="true"
        className={getStyling()}
      />
      {invalid ? (
        <p
          className={classes.textAreaError}
          id="text-area-input-error"
          aria-errormessage="text-area-error"
          data-testid="text-area-error"
        >
          {error}
        </p>
      ) : null}
    </>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

TextArea.defaultProps = {
  id: "-1",
  placeholder: "",
  required: false,
  disabled: false,
  invalid: false,
  value: "",
  onChange: (id, value) => {
    console.log(id, value);
  },
  error: "",
};

export default TextArea;
