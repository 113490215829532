import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ProcessComponent from '../../ui/processComponent/ProcessComponent';
import styles from './CreateEditWave.module.css';
import Button from '../../ui/buttons/Button';
import Logo from '../../ui/logo/Logo';
import { updateTitle } from '../../../utility/utility';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getProjectIds,
  GetWave,
  updateCrumbs,
  CreateWave,
  UpdateWave,
  DeleteWave,
  GetProcesses,
  getNotifications,
  sendNotification,
  GetWaveStatuses,
} from '../../../store/actions';
import { CopyWave, GetCountriesWaves } from '../../../store/actions/wave';
import Dropdown from '../../ui/Dropdown/Dropdown';
import { showToaster } from '../../../utility/utility';
import Checkbox from '../../ui/checkbox/Checkbox';
import ModalDialog from '../../ui/modal/Modal';
import WaveDeletePopup from '../../ui/waveDeletePopup/WaveDelete';
import WaveCopyPopup from '../../ui/waveCopyPopup/WaveCopy';
import {
  setWaveStatus,
  getProcessId,
  setProcessInState,
  autofillProcessArr,
  validateWave,
  getScrollElement,
} from './createWaveUtil';

/**
 * @class CreateEditWave
 * @param {Boolean} autofillProcess - Determines the behavior when creating a new wave or editing an existing one:
 * - true: When creating a new wave, setting the start date, end date, and status for any process will cause all other processes to automatically have their start date, end date, and status set. The status will be 'pending'. For the first process, subsequent processes are set to start a week apart, each beginning on the end date of the previous process. For processes that are not first, each preceding process will have its end date adjusted to the start date of the next process, and its start date set to 7 days before its own end date.
 * - false: When editing an existing wave, the start and end dates, as well as statuses of processes, will not be automatically updated when any process's dates or status are edited.
 *
 * @description This component allows user to create, edit delete and copy waves ( of the same type in the same country) available for a user. Each wave contains multiple processes, and has several wave specific values it accepts ( the wave's name, its type, test link, final report link as well as its wave type ).
 *
 * Below is a list of the most important aspects of this component:
 *
 * Wave type - each wave can have a specific wave type, as well as a year corresponding to the modules that are selectable in that year.
 *   - Consumer Tracker - contains 5 processes, including 'Module Selection', however any of them can be removed and / or restored by the user.
 *   - Incidence Tracker - contains 4 processes, as it does not include Module Selection, and the user can remove and/or restore any of the existing processes.
 *   - Booster - separate from Consumer and Incidence tracker, as any wave can be a booster wave.
 *
 * Processes:
 *   - At least one process must exist for the wave to be valid.
 *   - Must have start date, end date and a status for the wave be valid.
 *   - Can be removed and restored freely by the user.
 *
 * Features:
 *   - Notifications - when a wave is created / edited a notification is sent to the appropriate correspondent to inform them of the change that has been made ( be it that they are ready to select their desired modules or that their test/reporting link has been set and is available to be checked).
 *   - Delete wave - all waves can be deleted by clicking on the Delete wave button.
 *   - Update wave - update a wave to reflect the changes made to it ( as long as they are valid ).
 *   - Copy wave - if at least one wave of the same type is available in the same country the user can copy that wave's configuration over to the wave they are creating / editing.
 *   - Toaster - if the user tries to submit the changes they make but the submission is not valid a Toaster component will be shown informing the user of the changes they need to make to be able to submit.
 *
 * @returns {JSX}
 */
const CreateEditWave = ({ autofillProcess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { country, waveId } = useParams();

  const [waveName, setWaveName] = useState('');
  const [testLink, setTestLink] = useState('');
  const [reportingLink, setReportingLink] = useState('');
  const [processArr, setProcessArr] = useState({});
  const [currentProject, setCurrentProject] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [autofillProcesses, setAutofillProcesses] = useState(
    autofillProcess ? autofillProcess : false
  );
  const [boosterWave, setBoosterWave] = useState();
  const [sendNotif, setSendNotif] = useState(false);
  const [dataOrig, setDataOrig] = useState({
    testLink: '',
    reportingLink: '',
  });
  const [errorReport, setErrorReport] = useState({});
  const [showCopySuccessMsg, setShowCopySuccessMsg] = useState(false);
    
  const moduleSelection = useRef();
  const scripting = useRef();
  const fieldwork = useRef();
  const dataProcessing = useRef();
  const reporting = useRef();
  const modalContentType = useRef(null);
  const currentCountryId = useRef();
  const countryWaves = useRef();
  const ref = useRef({});
  const scrollTo = useRef(null);
  const sourceWaveId = useRef('');

  const wave = useSelector((state) => state.wave.wave);
  const waveStatuses = useSelector((state) => state.wave.waveStatuses);
  const processes = useSelector((state) => state.wave.wave.waveProcesses); //existing processes for this wave
  const waveProcessList = useSelector((state) => state.wave.processes);
  const countriesFromState = useSelector((state) => state.wave.countries);
  const projects = useSelector((state) => state.app.projects);
  const { notifications } = useSelector((store) => store.notifications);
  const messages = useSelector((state) => state.message.messages); 
  /**
   * @function
   * @memberof CreateEditWave
   * @param {String} type Name of the process that is being updated
   * @param {'startDate' | 'endDate' | 'status'} key 'startDate', 'endDate' or 'status', depending on which one is edited
   * @param {String} val 'the value of the start/end date, or the status value.
   *
   * @description This function branches into two possible cases:
   * - Editing a process on an existing wave: if this is the case the function will only update the process that was edited 'ProcessArr' state variable to reflect the change ( either updating the start/end date or status )
   * - Editing a process when creating a new wave - this case branches into the following two cases:
   *   - If the user populated the start and end dates as well as the status of ONLY ONE of the processes the other processes will be automatically populated. For the first process, subsequent processes are set to start a week apart, each beginning on the end date of the previous process. For processes that are not first, each preceding process will have its end date adjusted to the start date of the next process, and its start date set to 7 days before its own end date.
   *   - If the user populated at least one of the processes ( which causes all other processes to be automatically populated ) then the changes the user makes to the process' start/end date and process will not have any effect on any other processes for this wave.
   - For all cases/sub-cases the state variable 'processArr' will be updated accordingly.
  */
  const handleUpdatedProcess = (type, key, val) => {
    let procId = getProcessId(type, waveProcessList);
    let currentProcess = {};
    if (processArr.hasOwnProperty(procId)) {
      currentProcess = { ...processArr[procId] };
    } else {
      currentProcess = { processId: procId, processName: type };
    }
    currentProcess[key] = val;

    if (!waveId && autofillProcesses) {
      if (
        currentProcess.hasOwnProperty('startDate') &&
        currentProcess.hasOwnProperty('endDate') &&
        currentProcess.hasOwnProperty('status')
      ) {
        const autofilledObj = autofillProcessArr(currentProcess, processArr);
        setProcessArr({ ...autofilledObj, [procId]: currentProcess });
        setAutofillProcesses(false); //so it doesn't recalculate again if an autofilled process is updated in the same call

        Object.values(processArr).forEach((proc) => {
          switch (proc.processName) {
            case 'Module selection':
              moduleSelection.current = proc;
              break;
            case 'Scripting':
              scripting.current = proc;
              break;
            case 'Fieldwork':
              fieldwork.current = proc;
              break;
            case 'Data processing':
              dataProcessing.current = proc;
              break;
            case 'Reporting':
              reporting.current = proc;
              break;
            default:
              break;
          }
        });
      } else {
        setProcessArr((prevState) => ({
          ...prevState,
          [procId]: currentProcess,
        }));
      }
    } else {
      setProcessArr((prevState) => ({
        ...prevState,
        [procId]: currentProcess,
      }));
    }
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {Number} waveId
   *
   * @description This function is responsible for dispatching email notifications based on the current state of module selection and the availability of a test and reporting link. It handles the following cases:
   * - Module Selection - checks to see if the Module Selection process exists on this wave, as well as its status. If it exists and its status is 'In progress' the function dispatches 'sendNotification(notifications.makeSelection, currentCountryId.current, waveId). If 'notifications.makeSelection' is not undefined it sends an email to the corresponding JTI Country Research Manager informing them that their module and local question selections are due to be made.
   * - Review Test Link - checks to see if the test link is available, as well as ensure it's longer than 3 characters. If these conditions are met the function dispatches 'sendNotification(notifications.reviewTest, currentCountryId.current, waveId). If 'notifications.reviewTest' is not undefined it sends an email to the corresponding JTI Country Research Manager informing them that the test link is ready to be reviewed.
   * - Review Reports - checks to see if the report link is available, as well as ensure it's longer than 3 characters. If these conditions are met the function dispatches 'sendNotification(notifications.reviewReports, currentCountryId.current, waveId). If 'notifications.reviewReports' is not undefined it sends an email to the corresponding JTI Country Research Manager informing them that the project reports are ready to be reviewed.
   */
  const handleNotifications = (waveId) => {
    /* notify make selection */
    if(waveId) {
      if (IsWaveProcessModuleSelectionSetToInProgress()) {
        dispatch(
          sendNotification(
            notifications.makeSelection,
            currentCountryId.current,
            waveId
          )
        );
      }
  
      /* notify review testLink */
        if (dataOrig.testLink === '' && testLink.length > 3) {
        dispatch(
          sendNotification(
            notifications.reviewTest,
            currentCountryId.current,
            waveId
          )
        );
      }
  
      /* notify review reports */
        if (dataOrig.reportingLink === '' && reportingLink.length > 3) {
        dispatch(
          sendNotification(
            notifications.reviewReports,
            currentCountryId.current,
            waveId
          )
        );
      }
    }
  };

   /**
   * @function
   * @memberof CreateEditWave
   * @description Validate if Module selection process set to In Progress on create/update Wave
   */
  const IsWaveProcessModuleSelectionSetToInProgress = () => {
    let procId = getProcessId("Module selection", waveProcessList);
    return (
      (!moduleSelection.current ||
        moduleSelection.current?.status !== "In progress") &&
      processArr.hasOwnProperty(procId) &&
      processArr[procId].status === "In progress"
    );
  };
  /**
   * @function
   * @memberof CreateEditWave
   * @description Navigates the page to `/waves/${country}`;
   */
  const handleCancel = () => {
    navigate(`/waves/${country}`);
  };

  /**
   * @function
   * @memberof CreateEditWave
   */
  const handleBoosterCheckboxClick = (id) => {
    console.log('booster checkbox clicked', id);
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {Boolean} checked

   * @description Sets the value of the 'boosterWave' state variable to the value of the 'checked' argument.
   */
  const handleBoosterCheckboxChange = (checked) => {
    setBoosterWave(checked);
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {'delete' | 'copy'} action 'delete' or 'copy' depending on the button that was clicked.
   *
   * @description This function executs one of two conditionals depending on the value of of the action argument. In both cases the modal is hidden.
   * - action === 'delete': Dispatches 'DeleteWave' to remove the wave being edited, and then navigates to ./waves/${country}.
   * - action === 'copy': Shows a message stating that the wave was copied successfully.
   */
  const handleHideModal = (action) => {
    setShowModal(false);
    if (action === 'delete') {
        dispatch(DeleteWave(waveId, currentCountryId.current))
      navigate(`/waves/${country}`);
    } else if (action === 'copy') {
      setShowCopySuccessMsg(true);
    }
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {'copy' | 'delete'} type 'Either 'copy' or 'delete', depending on which button is clicked.
   *
   * @description This function calls 'setShowModal(true)' - setting the state variable 'showModal' to true, and sets 'modalContentType.current' to the value of the 'type' argument, which can be either 'copy' or 'delete'.
   *
   * The value of 'modalContentType.current' will determine if the component contained by the modal component 'ModalDialog' is either 'WaveCopyPopup' or 'WaveCopyPopup'.
   */
  const handleShowModal = (type) => {
    setShowModal(true);
    modalContentType.current = type;
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {String} name Name of the wave that will be copied
   *
   * @description The function checks 'countryWaves.current' to find the wave whose name is clicked when choosing a wave to copy. After finding it it updates 'sourceWaveId.current' to equal the wave that is being copied.
   */
  const handleGetSourceWaveId = (name) => {
    let tempWave = countryWaves.current.find((wave) => wave.name === name);
    sourceWaveId.current = tempWave.waveId;
  };

  /**
   * @function
   * @memberof CreateEditWave
   *
   * @description The function is used to decide what content should be displayed in the modal ModalDialog copmonent -  either 'WaveCopyPopup' or 'WaveDeletePopup', depending on the value of 'modalContentType.current'.
   *
   * @returns {JSX} The component returns one of two components:  'WaveCopyPopup', or 'WaveDeletePopup', depending on if the user clicks 'Copy existing wave', or 'Delete wave'.
   */
  const modalContent = () => {
    // This function has been rewritten becase in the previous case regardless of the value of 'modalContentType.current' both components would be created and stored into memory. Although this may have minor effects on performance it is more optimal/memory efficient to only instantiate the component needed ( based on the value of 'modalContentType.current').
    if (modalContentType.current === 'copy') {
      return (
        <WaveCopyPopup
          handleClose={handleHideModal}
          waves={countryWaves.current}
          getSourceWaveId={(name) => handleGetSourceWaveId(name)}
        />
      );
    } else if (modalContentType.current === 'delete') {
      return (
        <WaveDeletePopup waveName={waveName} handleClose={handleHideModal} />
      );
    }
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {String} processName  Name of the process being deleted.
   *
   * @description The function checks if the 'processArr' state variable includes a process whose key is equal to the id found by the 'getProcessId' function. The function performs two actions, each being independent of the other:
   * - If the value of 'scrollTo.current' ( which is the index of the process that the page is scrolled to if an issue is found with the process, such as it not having a start date, end date or status ) equals the id of the process being removed the 'errorReport' state variable is updated to delete the error corresponding to that process from it.
   * - If 'processArr' includes the process that is being removed it updates the 'processArr' state variable and sets the value of the 'deleted' property on that process to true.
   */
  const handleProcessDelete = (processName) => {
    let processId = getProcessId(processName, waveProcessList);
    if (processArr.hasOwnProperty(processId)) {
      if (scrollTo.current === processId) {
        setErrorReport((prevState) => {
          const copy = { ...prevState };
          delete copy['failedProcesses'][processId];
          return copy;
        });
      }
      setProcessArr((prevState) => ({
        ...prevState,
        [processId]: {
          ...prevState[processId],
          deleted: true,
        },
      }));
    }
    console.log(errorReport, 'error report after deleting');
  };

  /**
   * @function
   * @memberof CreateEditWave
   * @param {String} processName  Name of the process being restored.
   *
   * @description The function checks if the 'processArr' state variable includes a process whose key is equal to the id found by the 'getProcessId' function. If the 'stateProcesses' array does include the process being restored, it updates the process to the value of the 'deleted' property to false.

   */
  const handleProcessRestore = (processName) => {
    let processId = getProcessId(processName, waveProcessList);
    if (processArr.hasOwnProperty(processId)) {
      setProcessArr((prevState) => ({
        ...prevState,
        [processId]: {
          ...prevState[processId],
          deleted: false,
        },
      }));
    }
  };

  /**
   * @function
   * @memberof CreateEditWave
   *
   * @description The function performs several actions to handle the creation/editing (including copying) of a wave:
   *  1. Checks to make sure that if the wave type is 'Incidence tracker' the 'Module selection' process is deleted.
   *  2. Validates the wave using the 'validateWave' function. If any errors are found the report variable will include the errors, failed processes and error messages, as well as have its 'valid' property set to false. Otherwise the value of the 'valid' property will be true, and all other keys in the 'report' variable will be falsy. Lastly it updates the value of the 'errorReport' state variable to that of 'report'.
   *  3. Creates a variable 'waveData' containing all data pertaining to the wave.
   * Depending on if the error report is valid or not, the function performs the following actions:
   *  - 'valid' === true:
   *    - If the wave is new it dispatches 'CreateWave(waveData,
        sendNotification,
        notifications.makeSelection,
        currentCountryId.current,
        IsWaveProcessModuleSelectionSetToInProgress
        )' to create the new wave and post the wave to the database, as well send email notification if module selection process set to in progress.
   *    - If 'sourceWaveId.current' is not falsy, meaning that the user is intending to copy a wave (whose waveId is set to the value of 'sourceWaveId.current' ), then dispatches 'CopyWave(waveId, sourceWaveId.current, waveData)' to copy the wave, as well as post the wave to the database.
   *    - If neither of the two cases above are met. it means that the user is editing an already existing wave, and it dispatches 'UpdateWave(waveData)', as well as posts the wave to the database.
   *  - 'valid' === false:
   *    - A toaster is displayed showing the error found in the report, as well as scrolls the page to the element in which the error occurred. If multiple errors occurred they will be included in the error report as an ordered list and the page will be scrolled to the topmost element with an error.
   */
  const handleCreateEditWave = () => {
    const tempArr = Object.values(processArr);
    const filteredArr = [];
    let moduleSelection = waveProcessList.find(
      (item) => item.name === 'Module selection'
    )['processId'];
    tempArr.forEach((process) => {
      if (
        !(
          currentProject?.name?.toLowerCase().indexOf('incidence tracker') !==
            -1 && process.processId === moduleSelection
        ) &&
        !(process.hasOwnProperty('deleted') && process.deleted === true)
      ) {
        filteredArr.push(process);
      }
    });

    const report = validateWave(processArr, filteredArr, waveName);

    const waveStatus = setWaveStatus(
      filteredArr,
      waveStatuses,
      waveProcessList
    );
      const waveData = {
          name: waveName.trim(),
      countryId: currentCountryId.current,
      projectId: currentProject.projectId,
      waveStatusId: waveStatus,
      waveProcesses: filteredArr,
      testLink: testLink,
      reportingLink: reportingLink,
      startDate: filteredArr[0]?.startDate,
      endDate: filteredArr.at(-1)?.endDate,
      booster: boosterWave,
    };
    if (waveId) {
      waveData.waveId = waveId;
    }

    setErrorReport(report);

    if (report.valid) {
      if (!waveId) {
         dispatch(CreateWave(waveData,
              sendNotification,
              notifications.makeSelection,
              currentCountryId.current,
              IsWaveProcessModuleSelectionSetToInProgress()
          ));
      } else if (sourceWaveId.current !== '') {
        console.log(sourceWaveId.current);
        dispatch(CopyWave(waveId, sourceWaveId.current, waveData));
      } else {
        dispatch(UpdateWave(waveData));
        }
        setSendNotif(true);
    } else {
      const toasterContent = {
        title: "We found some issues",
        message: '',
      };
      toasterContent.message = report.errorMessages;
      showToaster(toasterContent, true);

      scrollTo.current = getScrollElement(report);

      if (scrollTo.current != '') {
        setTimeout(() => {
          ref.current[scrollTo.current]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 50);
      }
    }
  };

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @description When the component mounts it dispatches 'GetProcesses', 'GetWaveStatuses', 'getProjectIds', 'getNotifications', 'GetCountriesWaves' to get the required data for the creation of a wave from the database. If the wave already exists ( which can be told by the fact that it already has a waveId ), then GetWave(waveId) is also dispatched to get the wave's data as well and be able to edit it.
   */
  useEffect(() => {
    dispatch(GetProcesses());
    dispatch(GetWaveStatuses());
    if (waveId) {
      dispatch(GetWave(waveId));
    }
    dispatch(getProjectIds());
    dispatch(getNotifications());
    dispatch(GetCountriesWaves());
  }, []);

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @description The effect checks if 'currentCountryId.current' is falsy, as well as if 'countriesFromState' is truthy. If this is the case it sets the value of 'currentCountryId.current' to the value of the corresponding country taken from 'countriesFromState' ( based on the country name ).
   
  * This effect is triggered every time 'countriesFromState' updates, however this happens only once, when initially taken from the store.
  */
  useEffect(() => {
    if (countriesFromState.length > 0 && !currentCountryId.current) {
      let currentCountry = countriesFromState.filter(
        (cntry) => cntry.name == country
      );
      currentCountryId.current = currentCountry[0].countryId;
    }
  }, [countriesFromState]);

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @param {Object} wave All data pertaining to the current wave.
   * @param {Object} projects Object containing all project types, each one containing the keys 'isActive', 'name' and 'projectId'. Each key of a child corresponds to the child's projectId.
   *
   * @description If 'projects' and 'wave.projectId' are not falsy the effect sets the value of the 'currentProject' state variable to the corresponding item in the 'projects' using the key represented by 'wave.projectId'. If 'wave.propectId' is falsy, then it defaults to setting the 'currentProject' state variable to the child in the 'projects' object that has a key "Consumer Tracker 2024".
   *
   * This effect is triggered every time 'wave' or 'projects' update, however this happens only once, when they are initially taken from the store.
   * */
  useEffect(() => {
    if (projects) {
      if (wave?.projectId) {
        setCurrentProject(projects[wave.projectId]);
      } else {
        setCurrentProject(
          Object.values(projects).find(
            (el) => el.name === 'Consumer Tracker 2024'
          )
        ); // UPDATE PLEASE, Should look at active projects from state
        // Consumer tracker by default.
      }
    }
  }, [wave, projects]);

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @param {Number} currentCountryId.current Id of the wave that will be copied.
   *
   * @description When there is at least one wave available for the country corresponding to the wave being edited the effect retrieves the waves for the current country, identifying the target wave's project ID and booster status, and then it filters the country waves, excluding the target wave but including others that share the same project ID and booster status as the target wave.
   *
   * This effect is triggered every time 'currentCountryId.current' updates, however this happens only once, after 'countriesFromState' is taken from the store.
   */
  useEffect(() => {
    //waves available for copy
    console.log('countryWaves.current', countryWaves.current);
    if (!countryWaves.current && countriesFromState.length > 0) {
      let currentwaves = countriesFromState.find(
        (el) => el.countryId === currentCountryId.current
      )?.waves;
      let currentProjectId = currentwaves.find(
        (el) => el.waveId == waveId
      )?.projectId;
      let isTargetWaveBooster = currentwaves.find(
        (el) => el.waveId == waveId
      )?.booster;

      countryWaves.current = currentwaves.filter((wave) => {
        let isBooster = wave.booster;
        if (
          wave.waveId != waveId &&
          wave.projectId === currentProjectId &&
          isTargetWaveBooster === isBooster
        ) {
          return wave;
        }
      });
    }
  }, [currentCountryId.current]);

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @param {Array} processes Array of all processes available for this wave. Each process contains its process specific data, such as the name, start date, end date, status, etc.
   *
   * @description If both 'processes' and 'waveId' are truthy, then every time 'processes' is updated in the store the effect updates the 'processArr' state variable to reflect the changes. After updating the 'processArr' state variable, the ref of each process is updated to reflect the changes.
   */
  useEffect(() => {
    if (processes && waveId) {
      const tempObj = {};
      processes.forEach((process) => {
        if (process.waveId == waveId) {
          let procId = process.process
            ? process.process.processId
            : process.processId;
          let buildObj = {
            waveProcessId: process.waveProcessId,
            status: process.status,
            startDate: process.startDate,
            endDate: process.endDate,
            waveId: process.waveId,
            processId: procId,
          };
          tempObj[procId] = buildObj;
        }
      });
      setProcessArr(tempObj);

      moduleSelection.current = setProcessInState(processes, 1, waveId);
      scripting.current = setProcessInState(processes, 2, waveId);
      fieldwork.current = setProcessInState(processes, 3, waveId);
      dataProcessing.current = setProcessInState(processes, 4, waveId);
      reporting.current = setProcessInState(processes, 5, waveId);
    }
  }, [processes]);

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @param {Object} wave All data pertaining to the current wave.
   *
   * @description The effect ensures that every time 'wave' changes the store, the matching state variables ( including its name, test link and reporting link ) are updated to reflect the changes in the wave data. It also updates  Breadcrumbs component as well as the page title to reflect the data available in the store.
   */
  useEffect(() => {
    if (wave.booster && boosterWave != wave.booster) {
      setBoosterWave(wave.booster);
    }

    let currentWave = 'New wave';
    if (wave.waveId && waveId) {
      currentWave = wave.name;
      setWaveName(wave.name ? wave.name : '');
      setTestLink(wave.testLink ? wave.testLink : '');
      setReportingLink(wave.reportingLink ? wave.reportingLink : '');
      setDataOrig({
        testLink: wave.testLink ? wave.testLink : '',
        reportingLink: wave.reportingLink ? wave.reportingLink : '',
      });
    }

    dispatch(
      updateCrumbs([
        { text: 'Waves', link: '/' },
        { text: country, link: `waves/${country}` },
        { text: `${currentWave}`, link: null },
      ])
    );
    updateTitle(` | ${wave.name}`);
  }, [wave]);

  /**
   * @method useEffect
   * @memberof CreateEditWave
   * @param {Boolean} sendNotif Boolean, is set to true if the report generated in the function 'handleCreateEditWave' is valid.
   * @param {Object} wave All data pertaining to the current wave.
   *
   * If 'wave' is truthy, and the value of 'sendNotif' is true the 'handleNotifications' function is called to send the appropriate notifications ( if applicable ), and then the application is navigated to `/waves/${country}`.
   * */
  useEffect(() => {
    /* handle notifications */
    if (sendNotif && Object.keys(wave).length > 0) {
      console.log('current wave', wave);
      let waveId = wave.waveId;
      handleNotifications(waveId);    
      navigate(`/waves/${country}`);
    }
  }, [sendNotif, wave]);

  return (
    <div className={styles.PageWrapper}>
      <Logo customStyle={styles.FullscreenLogo} />
      <ModalDialog show={showModal} content={modalContent()} />
      <div className={styles.Title}>
        <h2> {`${waveId ? 'Edit wave' : 'Create wave'}`}</h2>       
      </div>
      <div className={styles.PageContent}>
        <div>
          <form>
            <Dropdown
              className={styles.dropdown}
              id={'cntry'}
              label={'Country'}
              value={country}
              options={[{ label: country, value: country }]}
              placeholder={' '}
              onChange={() => {}}
              customStyle={styles.dropdown}
            />
            <label>
              Wave name
              <input
                className={`${
                  errorReport.errors?.includes('missingName') && !waveName
                    ? styles.Error
                    : ''
                }`}
                type="text"
                value={waveName}
                onChange={(e) => setWaveName(e.target.value)}
                ref={(el) => (ref.current['wnb'] = el)}
              />
            </label>
            <label>
              Test link
              <input
                type="text"
                value={testLink}
                onChange={(e) => setTestLink(e.target.value)}
              />
            </label>
            <label>
              Final Report
              <input
                type="text"
                value={reportingLink}
                onChange={(e) => setReportingLink(e.target.value)}
              />
            </label>
            <Dropdown
              className={styles.dropdown}
              id={'projectType'}
              label={'Wave type'}
              options={
                projects && Object.values(projects).length
                  ? Object.values(projects).map((item) => ({
                      label: item?.name,
                      value: item?.projectId,
                    }))
                  : null
              }
              value={currentProject?.projectId}
              onChange={(e) => {
                setCurrentProject(projects[e.target.value]);
                if (
                  projects[e.target.value]?.name
                    ?.toLowerCase()
                    .indexOf('incidence tracker') !== -1
                ) {
                  handleProcessDelete('Module selection');
                }
              }}
              customStyle={styles.dropdown}
            />
            <Checkbox
              label="Booster wave"
              checked={boosterWave}
              disabled={waveId > 0 && wave.booster ? true : false}
              id={waveId ? waveId : `newWave_${country}`}
              clicked={(id) => handleBoosterCheckboxClick(id)}
              changed={(checked, id) =>
                handleBoosterCheckboxChange(checked, id)
              }
            />
          </form>
          {wave.waveId &&
            waveId &&
            currentProject?.name?.toLowerCase().indexOf('incidence tracker') ===
              -1 && (
              <div className={`${styles.BtnWrapper}`}>
                <Button
                  type="secondary"
                  handleOnClick={() => handleShowModal('copy')}
                >
                  Copy existing wave
                </Button>
              </div>
            )}
          {showCopySuccessMsg && (
            <div className={styles.SuccessMsg}>
              {messages?.copyWaveSuccessMessage?.text}
            </div>
          )}
        </div>
        <div className={styles.FlexWrapper}>
          {currentProject?.name?.toLowerCase().indexOf('incidence tracker') !==
          -1 ? null : (
            <ProcessComponent
              type="Module selection"
              startDate={moduleSelection.current?.startDate}
              endDate={moduleSelection.current?.endDate}
              status={moduleSelection.current?.status}
              onChange={handleUpdatedProcess}
              isNewWave={waveId ? false : true}
              onDelete={handleProcessDelete}
              onRestore={handleProcessRestore}
              isValidFail={errorReport.failedProcesses?.hasOwnProperty('1')}
              ref={(el) => (ref.current[1] = el)}
            />
          )}
          <ProcessComponent
            type="Scripting"
            startDate={scripting.current?.startDate}
            endDate={scripting.current?.endDate}
            status={scripting.current?.status}
            onChange={handleUpdatedProcess}
            isNewWave={waveId ? false : true}
            onDelete={handleProcessDelete}
            onRestore={handleProcessRestore}
            isValidFail={errorReport.failedProcesses?.hasOwnProperty('2')}
            ref={(el) => (ref.current[2] = el)}
          />
          <ProcessComponent
            type="Fieldwork"
            startDate={fieldwork.current?.startDate}
            endDate={fieldwork.current?.endDate}
            status={fieldwork.current?.status}
            onChange={handleUpdatedProcess}
            isNewWave={waveId ? false : true}
            onDelete={handleProcessDelete}
            onRestore={handleProcessRestore}
            isValidFail={errorReport.failedProcesses?.hasOwnProperty('3')}
            ref={(el) => (ref.current[3] = el)}
          />
          <ProcessComponent
            type="Data processing"
            startDate={dataProcessing.current?.startDate}
            endDate={dataProcessing.current?.endDate}
            status={dataProcessing.current?.status}
            onChange={handleUpdatedProcess}
            isNewWave={waveId ? false : true}
            onDelete={handleProcessDelete}
            onRestore={handleProcessRestore}
            isValidFail={errorReport.failedProcesses?.hasOwnProperty('4')}
            ref={(el) => (ref.current[4] = el)}
          />
          <ProcessComponent
            type="Reporting"
            startDate={reporting.current?.startDate}
            endDate={reporting.current?.endDate}
            status={reporting.current?.status}
            onChange={handleUpdatedProcess}
            isNewWave={waveId ? false : true}
            onDelete={handleProcessDelete}
            onRestore={handleProcessRestore}
            isValidFail={errorReport.failedProcesses?.hasOwnProperty('5')}
            ref={(el) => (ref.current[5] = el)}
          />
        </div>
        <div className={styles.SaveBtns}>
          {wave.waveId && waveId && (
            <div className={`${styles.BtnWrapper} ${styles.BtnDelete}`}>
              <Button
                type="secondary"
                handleOnClick={() => handleShowModal('delete')}
              >
                Delete wave
              </Button>
            </div>
          )}
          <div className={styles.BtnWrapper}>
            <Button type="secondary" handleOnClick={() => handleCancel()}>
              Cancel
            </Button>
          </div>
          <div className={`${styles.BtnWrapper} ${styles.Last}`}>
            <Button
              type="primary"
              handleOnClick={() => handleCreateEditWave()}
            >{`${waveId ? 'Update wave' : 'Create wave'}`}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

CreateEditWave.propTypes = {
  autofillProcess: PropTypes.bool,
};

CreateEditWave.defaultProps = {
  autofillProcess: true,
};

export default CreateEditWave;
