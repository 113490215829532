import * as actionTypes from './actionTypes';
import axios from '../../axiosInstance';
import {
  buildModuleState,
  buildSelectedModulePayload,
} from '../../utility/utility';
import { result } from 'lodash';

export const fetchModulesListStart = () => {
  return {
    type: actionTypes.FETCH_MODULES_LIST_START,
  };
};

export const fetchModulesListSuccess = (result) => {
  return {
    type: actionTypes.FETCH_MODULES_LIST_SUCCESS,
    moduleList: result.moduleList,
    groupList: result.groupList,
    orderedList: result.orderedList
  };
};

export const getAllProjectModulesSuccess = (result) => {
  return {
    type: actionTypes.GET_ALL_PROJECT_MODULES_SUCCESS, 
    projectModuleList: result.moduleList,
  }
}

export const fetchModulesListFail = (error) => {
  return {
    type: actionTypes.FETCH_MODULES_LIST_FAIL,
    error: error,
  };
};

export const ClearModules = () => {
  return {
    type: actionTypes.CLEAR_MODULES_DATA,
  };
};

export const fetchModulesList = (
  projectId,
  waveId,
  countryId,
  isBoosterWave
) => {
  return (dispatch) => {
    dispatch(fetchModulesListStart());
    Promise.all([
      axios.get(
        '/Projects/' +
          projectId +
          '/countries/' +
          countryId +
          '/SurveyModules?currentWaveId=' +
          waveId
      ),
      axios.get('/Waves/' + waveId + '/Modules'),
    ])
      .then(async ([resModules, resSelected]) => {
        dispatch(
          fetchModulesListSuccess(
            buildModuleState(isBoosterWave, resModules.data, resSelected.data)
          )
        );
      })
      .catch((err) => {
        dispatch(fetchModulesListFail(err));
      });
  };
};



export const getAllProjectModules = (
  projectId,  
  countryId  
) => {
  return (dispatch) => {
    dispatch(fetchModulesListStart());
    Promise.all([
      axios.get(
        '/Projects/' +
          projectId +
          '/countries/' +
          countryId +
          '/SurveyModules' 
      )
    ])
      .then(([resModules]) => {
        if (resModules.data != ''){
          dispatch(
            getAllProjectModulesSuccess(buildModuleState(false, resModules.data, ''))
          );
        }else {
          dispatch(fetchModulesListFail(resModules.statusText));
        }
      })
      .catch((err) => {
        dispatch(fetchModulesListFail(err));
      });
  };
};

export const updateModuleSelection = (id, checked) => {
  return {
    type: actionTypes.UPDATE_MODULE_SELECTION,
    id: id,
    checked: checked,
  };
};

export const batchUpdateModules = (updates) => ({
  type: actionTypes.BATCH_UPDATE_MODULES,
  updates,
});

export const updateGroupValidCheck = (id, valid) => {
  return {
    type: actionTypes.UPDATE_GROUP_VALIDATION,
    id: id,
    valid: valid,
  };
};

export const updateMultipleModuleSelection = (payload) => {
  return {
    type: actionTypes.UPDATE_MULTIPLE_MODULE_SELECTION,
    payload: payload,
  };
};


export const UpdateChangesToApprove = (approvalRdy) => {
  return {
    type: actionTypes.UPDATE_CHANGES_TO_APPROVE,
    approvalRdy: approvalRdy,
  };
};

export const saveModuleSelectionStart = () => {
  return {
    type: actionTypes.SAVE_MODULE_SELECTION_START,
  };
};

export const saveModuleSelectionSuccess = (response) => {
  return {
    type: actionTypes.SAVE_MODULE_SELECTION_SUCCESS,
    response: response,
  };
};

export const saveModuleSelectionFail = (error) => {
  return {
    type: actionTypes.SAVE_MODULE_SELECTION_FAIL,
    error: error,
  };
};

export const saveModuleSelection = (modules, waveId) => {
  const payload = buildSelectedModulePayload(modules);
  return (dispatch) => {
    dispatch(saveModuleSelectionStart());
    axios
      .post('/Waves/' + waveId + '/Modules', payload)
      .then((response) => {
        dispatch(saveModuleSelectionSuccess(response.data));
      })
      .catch((err) => {
        dispatch(saveModuleSelectionFail(err));
      });
  };
};

export const UpdatePageToggle = (showDetails) => {
  return {
    type: actionTypes.UPDATE_PAGE_TOGGLE,
    showQuestionDetails: showDetails,
  };
};
