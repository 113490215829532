import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatusGrid.module.css';
import StatusGridItem from './StatusGridItem';

const StatusGrid = ({
  statusItems,
  reportinglink,
  testlink,
  statusToStatusType,
  projectType,
}) => {
  return (
    <div className={styles.container}>
      {/* header */}
      <div className={`${styles.cell} ${styles.title}`}>Process</div>
      <div className={`${styles.cell} ${styles.title}`}>Status</div>
      <div className={`${styles.cell} ${styles.title}`}>Start date</div>
      <div className={`${styles.cell} ${styles.title}`}>End date</div>

      {/* statusitems */}
      {statusItems.map((item, index) => {
        return (
          <StatusGridItem
            key={index}
            statusType={statusToStatusType(item.status)}
            {...item}
            projectType={projectType}
          />
        );
      })}

      {/* testlink */}
      <div className={`${styles.cell} ${styles.lastrow}`}>
        <span className={styles.label}>Test link:</span>
        <span className={styles.link}>
          <a href={testlink} target="_blank" rel="noreferrer" alt="testlink">
            {testlink}
          </a>
        </span>
      </div>

      {/* reportinglink */}
      <div className={`${styles.cell} ${styles.lastrow}`}>
        <span className={styles.label}>Final Report:</span>
        <span className={styles.link}>
          <a
            href={reportinglink}
            target="_blank"
            rel="noreferrer"
            alt="reportinglink"
          >
            {reportinglink}
          </a>
        </span>
      </div>
    </div>
  );
};

StatusGrid.propTypes = {
  statusItems: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  testlink: PropTypes.string,
  translateStatus: PropTypes.func,
};

StatusGrid.defaultProps = {
  statusItems: [
    {
      process: { name: 'default' },
      status: 'default',
      statusType: 'default',
      startDate: 'default',
      endDate: 'default',
    },
  ],
  users: [{ user: { email: 'default' } }],
  testlink: '',
  statusToStatusType: (status) => {
    return status;
  },
};

export default StatusGrid;
